.contest-timer-container {
  padding: 1rem 3rem;
  height: 7.1875rem;
}

.contest-timer-label-container {
  display: flex;
  justify-content: space-between;
}

.contest-timer-resume-submission-label {
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #30374c;
}

.contest-timer-job-duration-label {
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #8a90a9;
}

@media only screen and (max-width: 780px) {
  .contest-timer-container {
    padding: 1rem;
    margin-bottom: 1rem;
  }
}

.bank-details {
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  color: #30374c;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

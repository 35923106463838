.onboarding-banner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.onboarding-banner {
  position: absolute;
  width: 100%;
  height: 56.25rem;
  left: 0rem;
  top: 0rem;
  background: #4d6cd9;
}

.onboarding-background {
  position: absolute;
  width: 100%;
  height: 75rem;
  left: 0rem;
  top: 21.5rem;
  background: #f1f7ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
}

.onboarding-logo-container {
  position: absolute;
  top: 4.75rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.onboarding-logo {
  width: 11.5625rem;
  height: 4.2rem;
  background-color: #4d6cd9;
}

.onboarding-card {
  position: absolute;
  width: 52.125rem;
  height: 74rem;
  top: -5.6875rem;
}

.job-description-container {
  background: #ffffff;
}

.job-description-tasks-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1.5rem;
}

.job-description-task-container {
  background: #f8f9fe;
  border: 0.125rem solid #4d6cd9;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 1.25rem;
  width: 23.375rem;
}

.job-description-task-title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #30374c;
}

.job-description-task-content {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #626880;
  margin-top: 0.75rem;
}

.job-description-title {
  font-weight: bold;
  font-size: 1.1875rem;
  line-height: 1.625rem;
  color: #30374c;
  margin-bottom: 1.5rem;
}

.job-description-content {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #626880;
}

@media only screen and (max-width: 780px) {
  .job-description-tasks-container {
    display: block;
  }
  .job-description-task-container {
    margin-bottom: 1.5rem;
    width: 100%;
    overflow-wrap: break-word;
  }
  .job-description-content {
    font-size: 1rem;
    line-height: 1.375rem;
    color: #626880;
    overflow-wrap: break-word;

    /* margin-bottom: 10px; */
  }
}

@media only screen and (max-width: 780px) {
  .job-description-tasks-container {
    display: block;
  }
  .job-description-task-container {
    margin-bottom: 1.5rem;
    width: 100%;
  }
}
iframe {
  width: 100%;
  height: 600px;
  border: 2px solid grey;
  border-radius: 5px;
}

@import "../../scssPartials/sharedStyles";

.book-a-call-wrapper {
  background: #4d6cd9;
  border-radius: 1.5rem;
  position: relative;
}

.book-a-call-container {
  text-align: center;
  padding: 3.25rem 21.1875rem;
  display: flex;
  flex-direction: column;
}

.book-a-call-text {
  @include font(1rem, 700, #ced4f0, 1.375rem);
  margin-top: 1.5rem;
}

.book-a-call-button {
  width: 11.1875rem;
  margin-top: 1.5rem;
  align-self: center;
}

.book-a-call-popup-container {
  background: #ffffff;
  border: 0.0625rem solid #d6d6d6;
  border-radius: 1rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem 5rem;
}

.book-a-call-popup-title {
  @include font(1.25rem, 700, #626880, 1.375rem);
  margin: 0 0 2rem 0;
}

.book-a-call-popup-input {
  width: 25.5rem;
}

.book-a-call-popup-button-container {
  display: flex;
  align-self: center;
  margin-bottom: 1rem;
}

.book-a-call-submit-button {
  font-weight: 700 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  border-radius: 1rem !important;
  margin-left: 1.5rem !important;
  box-shadow: none !important;
  padding: 0.455625rem 2.78125rem !important;
}

.book-a-call-popup-error-message {
  @include font(0.875rem, normal, #ff0000, 1.375rem);
  max-width: 26rem;
}

.book-a-call-popup-success-message {
  @include font(1rem, bold, #626880, 1.375rem);
  margin: 2rem 0;
  max-width: 26rem;
}

@media only screen and (max-width: 720px) {
  .book-a-call-container {
    text-align: center;
    padding: 2.5rem 0.5rem;
  }
}

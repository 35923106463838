@import "../../scssPartials/sharedStyles";
@mixin size-position($height, $width, $left, $top) {
  height: $height;
  width: $width;
  left: $left;
  top: $top;
}

.certificate {
  position: static;
  @include size-position(5.375rem, 25.375rem, 0rem, 0rem);
  padding: 0.5rem 0.75rem;
  background: #ffffff;
  border: 0.12rem solid var(--text-input-default-border);
  box-sizing: border-box;
  border-radius: 0.75rem;
  font-family: Nunito;
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--text-input-active-color);
  resize: none;
}

.certificate:focus {
  border: 0.09375rem solid var(--text-input-active-border);
  color: var(--text-input-actve-color);
  outline: none;
}

.certificate:disabled {
  color: var(--text-input-disabled-color);
  border: 0.09375rem solid var(--text-input-disabled-border);
  outline: none;
}

.uploadfile {
  width: 25.375rem;
  height: 5.125rem;
  @include display-align(row, flex-end, center);
}

.browse-label {
  @include size-position(fit-content, 14.25rem, 0rem, 0rem);
  font-size: 1rem;
  line-height: 1.375rem;
  color: #4d6cd9;
  display: flex;
  flex-direction: row;
}

.file-name {
  padding-left: 0.5rem;
}

.browse-note {
  @include size-position(1.5rem, 14.25rem, 0rem, 1.375rem);
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #626880;
}
.upload_logo_drag_drop {
  width: 19.5625rem !important;
  height: 7.375rem !important;
}

.file-status-image {
  @include size-position(1.25rem, 1.25rem, 00.125rem, 00.125rem);
}

.logo-dragdrop-card-wrapper {
  width: 24rem;
  height: 10rem;
  border: 0.0625rem solid #aab0cb;
  border-radius: 1rem;
  padding: 3rem;
  background-color: #fff;
  cursor: pointer;
}

.logo-dragdrop-card-container {
  border: 0.0625rem dashed #4d6cd9;
  border-radius: 0.8rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo-dragdrop-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-plus-icon {
  width: 1.375rem;
  height: 1.375rem;
}

.logo-dragdrop-text {
  font-size: 1.0625rem;
  color: #30374c;
}

.logo-display-container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.div-avatar-options {
  width: 25rem;
  height: 2rem;
  @include display-align(row, flex-end, center);
  margin-bottom: 0.5rem;
}
.avatar-option-button {
  width: 2rem;
  height: 2rem;
}
.drop-zone-label {
  color: var(--text-input-active-color);
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  width: 25rem;
}
.avatar-button {
  border: 0;
  background: #ffffff;
  cursor: pointer;
}

@import "../../scssPartials/sharedStyles";

.recruiter-contests-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.recruiter-contests-show-contests-won-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 1.75rem;
}

.recruiter-contests-contests-container {
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0px 0.25rem 1rem rgba(206, 212, 240, 0.8);
  padding-left: 5.6875rem;
  padding-top: 3.5rem;
}

.recruiter-contests-contest-container {
  margin: 1rem 0 0 0;
  padding-bottom: 2.5rem;
}

.recruiter-contests-contest-separator {
  margin: 0 5.6875rem 0 0;
  border-top: 0.125rem solid #dfe5ff;
}

.recruiter-contests-pagination-container {
  float: right;
  padding: 1.625rem 0 0 0;
  margin: 0 5.6875rem 0 0;
}

.recruiter-contests-per-page-container {
  display: flex;
  align-items: baseline;
  float: left;
  padding: 1.625rem 0 0 0;
}

.recruiter-contests-per-page {
  width: 6.75rem;
}

.recruiter-contests-per-page-text {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #626880;
}

.recruiter-contests-no-contests {
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #000000;
}

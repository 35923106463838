.input-text {
  &:disabled {
    border: 0.09375rem solid var(--text-input-disabled-border);
    color: var(--text-input-disabled-color);
  }
  &:focus {
    border: 0.09375rem solid var(--text-input-active-border);
    color: var(--text-input-actve-color);
    outline: none;
  }
}

.input-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.75rem;
  position: static;
  height: 2.625rem;
  left: 0rem;
  top: 0rem;
  background: #ffffff;
  border: 0.09375rem solid var(--text-input-default-border);
  box-sizing: border-box;
  border-radius: 0.75rem;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--text-input-active-color);
  outline: none;
}

.input-text::placeholder {
  color: #aab0cb;
  opacity: 1;
}

.input-text::-ms-input-placeholder {
  color: #aab0cb;
}

.textfield-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.textfield-wrapper label {
  pointer-events: none;
  position: absolute;
  transform: translate(0.8125rem, 0.6rem) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-in;
  z-index: 1;
  color: var(--text-input-default-color);
}

.active-label {
  color: var(--text-input-active-color);
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
}

.textfield-wrapper .active {
  @extend .active-label;
  transform: translate(0, -1.375rem) scale(1);
}

.is-invalid,
.is-invalid:focus {
  color: var(--error-message-color);
  border: 0.0625rem solid var(--error-message-color) !important;
}

.invalid-feedback {
  color: var(--error-message-color);
  font-size: 1rem;
  margin-bottom: 2rem;
}

.info-error-label {
  margin-top: 0.2rem;
  color: var(--error-message-color);
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  text-align: left;
  width: 18.75rem;
}

@import "../../scssPartials/sharedStyles";

.contest-filters-container {
  width: 16.625rem;
  padding: 1.1875rem 1rem 2rem 1rem;
}

.contest-filters-title-container {
  display: flex;
  align-items: center;
}

.contest-filters-title {
  @include font(1.0625rem, bold, #262626, 1.25rem);
  margin-left: 0.875rem;
}

.contest-filters-separator {
  border: 0.0625rem solid #dfe5ff;
  margin: 1.0625rem 0;
}

.contest-filters-clear-filters-button {
  margin-top: 1rem;
}


.parent-container {
  display:contents;
  align-items: center;
  justify-content: center;
  background: white;
}


.container1 {
  top: 64px; 
  left: 0px; 
  width: 100% !important; 
  height: 468px; 
  background:rgb(227, 78, 227) !important;
  border-radius: 4px; 
  margin-bottom: 2rem;
 
}
.textContainer{
  padding-top: 5rem;
 padding-left: 4rem;
 font-family: 'Nunito',sans-serif;
 color: #000;
 font-size: 2rem;
}
.refund-policy-container {
  margin: 0 3rem; 
  font-family: 'Nunito', sans-serif; 
  color: black;
}

h3{
  font-size: 1.5rem;
  margin: 1rem 0;
}
.refund-policy-container p{
  font-size: 1.4rem;
}
.refund-policy-Logo{
    position: absolute;
    top: 8rem;
    left: 55%;

}
.logo {
  margin-top: 0.5rem;
  height: 60%;
  width: 70%;
}


@import "../../scssPartials/sharedStyles";

.tag {
  background: #fbfbfb;
  @include border(0.09375rem solid #4d6cd9, 0.5rem);
  @include font(1rem, bold, var(--secondary-text-color), 1.375rem);
  padding: 0.25rem 0.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
}

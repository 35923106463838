@import "../../scssPartials/sharedStyles";

.accelerating-hiring-image {
  width: 100%;
  height: 22rem;
  object-fit: cover;
  object-position: right;
}
.accelerating-hiring-image-container {
  transition: width 0.5s ease-in-out;
  margin-right: 15px;
  position: relative;
  height: 22rem;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
}

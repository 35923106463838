.faq-container {
  background: #ffffff;
}

.faq-title {
  font-weight: bold;
  font-size: 1.1875rem;
  line-height: 1.625rem;
  color: #30374c;
  margin-bottom: 1.75rem;
}

.faq-accordion-wrapper {
  width: 52.125rem;
  margin: 0 auto;
}

.faq-accordion-container {
  margin-top: 1rem;
}

.faq-answer-container {
  background: #f1f7ff;
  border-radius: 0.75rem;
  padding: 1rem 1.9375rem 1rem 0.75rem;
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  color: #30374c;
}

.faq-no-faq {
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  color: #30374c;
  text-align: center;
}

@media only screen and (max-width: 780px) {
  .faq-accordion-wrapper {
    width: 100%;
  }
}
iframe {
  width: 100% !important;
  height: 600px;
  border: 2px solid grey;
  border-radius: 5px;
}


.edit-button {
  width: 25.375rem;
  height: 2.625rem;
}
.success-image {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 46.75rem;
}

.success-text {
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #30374c;
}

@import "../../scssPartials/sharedStyles";

.text-filter-container {
  display: flex;
  justify-content: space-between;
}

.text-filter-filter-title {
  @include font(1rem, bold, #30374c, 1.375rem);
}

.text-filter-expand-icon {
  cursor: pointer;
}

.text-filter-filter-name {
  @include font(1rem, normal, #30374c, 1.375rem);
}

.text-filter-text {
  margin-top: 1rem;
}

.text-filter-text-input {
  width: -webkit-fill-available;
  width: -moz-available;
}

@import "../../scssPartials/sharedStyles";

.contact-popup-container {
  background: #ffffff;
  border: 0.0625rem solid #d6d6d6;
  border-radius: 1rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem 5rem;
}

.contact-popup-title {
  @include font(1.25rem, 700, #626880, 1.375rem);
  margin: 0 0 2rem 0;
}

.contact-popup-input {
  width: 25.5rem;
  margin-bottom: 2rem;
}

.contact-popup-button-container {
  display: flex;
  align-self: center;
  margin-bottom: 1rem;
}

.contact-submit-button {
  font-weight: 700 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  border-radius: 1rem !important;
  margin-left: 1.5rem !important;
  box-shadow: none !important;
  padding: 0.455625rem 2.78125rem !important;
}

.contact-popup-error-message {
  @include font(0.875rem, normal, #ff0000, 1.375rem);
  max-width: 26rem;
}

.contact-popup-success-message {
  @include font(1rem, bold, #626880, 1.375rem);
  margin: 2rem 0;
  max-width: 26rem;
}

.contact-message-label {
  @include font(0.8125rem, bold, #626880, 1.125rem);
  margin-bottom: 0.5rem;
}

.contact-message {
  background: #ffffff;
  border: 0.09375rem solid #aab0cb;
  border-radius: 0.75rem;
  width: 24rem;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  outline: none;
  color: rgb(98, 104, 128);
}

.contact-message-error {
  color: red;
  font-size: 1rem;
  margin-bottom: 2rem;
}

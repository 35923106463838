@import "./timelinePartials";

.timeline-wrapper {
  height: 38.875rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-right: 0.4375rem;
}

.timeline-title {
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  color: #262626;
  margin: 1.514375rem 0 1.72375rem 1.5rem;
}

.timeline-container {
  padding-top: 1.5em;
  padding-right: 1.0625rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.timeline-item-container {
  @include timeline-item-container;
}

.timeline-active-item-container {
  @include timeline-item-container;
}

.timeline-inactive-item-container {
  @include timeline-item-container;
  border-left: 0.25rem solid #4d6cd9;
}

.timeline-item-container:last-child {
  border-left: 0;
  padding-bottom: 0;
  left: 0.2rem;
}

.timeline-item-container::before {
  @include timeline-item-container-before;
  height: 0.75em;
  width: 0.75rem;
  border: none;
  top: -0.5rem;
  left: -0.5rem;
}

.timeline-active-item-container::before {
  @include timeline-item-container-before;
}

.timeline-inactive-item-container::before {
  @include timeline-item-container-before;
}

.timeline-last-item-container::before {
  left: -0.5rem;
}

.timeline-item {
  @include timeline-item;
}

.timeline-active-item {
  @include timeline-item;
  background: #4d6cd9;
  border: 0.09375rem solid #9ba9e8;
}

.timeline-inactive-item {
  @include timeline-item;
  background: #e9f0ff;
  border: 0.09375rem solid #4d6cd9;
}

.timeline-item-title {
  @include timeline-item-title;
}

.timeline-item-description {
  @include timeline-item-description;
}

.timeline-active-item-title {
  @include timeline-item-title;
  color: #e8eafa;
}

.timeline-active-item-description {
  @include timeline-item-description;
  color: #e8eafa;
}

.timeline-last-item-container {
  border: none;
}

.timeline-inactive-last-item-container {
}

@media only screen and (max-width: 780px) {
  .timeline-wrapper {
    height: unset;
  }
}

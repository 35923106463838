.partner-tabs-container {
  display: flex;
  justify-content: space-between;
  background: #4d6cd9;
  border-radius: 1rem;
  width: auto;
}

.partner-tabs-tab {
  margin: 0.5rem;
  flex-grow: 1;
}

.partner-tabs-active-tab {
  background: #f1f7ff;
  border-radius: 12px;
}

.partner-tabs-text {
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  text-align: center;
  color: #ffffff;
  padding: 0.625rem 1.361875rem;
  cursor: pointer;
}

.partner-tabs-active-text {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #4d6cd9;
  cursor: default;
}

.partner-tab-container {
  background: #fefefe;
  box-shadow: 0px 0.25rem 1rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
  margin-top: 1.5rem;
  padding: 2.5rem 6.6875rem;
}

.partner-tabs-details-container {
  margin-top: 1.5rem;
}

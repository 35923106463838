.menu-label {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
  padding-left: 0.625rem;
}

.profile-menu-icon {
  height: 2.1875rem;
  width: 2.1875rem;
}

.profile-menu-icon-item {
  height: 1.5rem;
  width: 1.5rem;
}

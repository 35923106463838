.hotlist-container {
  margin: 1.375rem 1.5rem 0 1.5rem;
  padding-bottom: 2.5rem;
}

.hotlist-js-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.hotlist-js-view-container {
  display: flex;
}

.hotlist-js-title {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #262626;
}

.hotlist-filters {
  display: none;
}

.hotlist-js-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-row-gap: 1.75rem;
  grid-column-gap: 1.5rem;
  align-items: start;
}

.hotlist-js-list-view-container {
  flex-grow: 1;
}

.hotlist-pagination-container {
  align-self: center;
}

.hotlist-js-list-container {
  margin-bottom: 1.5rem;
}

.hotlist-no-js {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 2.5rem;
  margin-left: 3rem;
}

.hotlist-js-list-container {
  display: flex;
  gap: 1rem;
  width: -webkit-fill-available;
}

.hotlist-js-list-container > * {
  flex: 1;
}

.hotlist-js-filter-container {
  flex: 0.3;
}

.hotlist-connect-select {
  margin: auto;
}

@media (max-width: 67.5rem) {
  .hotlist-hero-image-container {
    padding: 1.5rem 1.5rem;
  }

  .hotlist-container {
    margin: 1.375rem 1.5rem 0 1.5rem;
    padding-bottom: 2.5rem;
  }
}

@media (max-width: 1024px) {
  .hotlist-js-view-container {
    display: none;
  }
  .hotlist-js-filter-container {
    display: none;
  }

  .hotlist-filters {
    display: block;
    margin-bottom: 1rem;
  }
}

@media (max-width: 50rem) {
  .hotlist-hero-image-container {
    padding: 1.5rem 1.5rem;
  }

  .hotlist-no-js {
    margin-left: 1rem;
  }

  .hotlist-title {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }

  .hotlist-title-text {
    font-size: 1rem;
    max-width: 90%;
  }

  .hotlist-logo {
    margin-top: 2rem;
    width: 5rem;
    height: 5rem;
  }

  .hotlist-container {
    margin: 1.375rem 1.5rem 0 1.5rem;
    padding-bottom: 2.5rem;
  }

  .hotlist-js-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 30rem) {
  .hotlist-hero-image-container {
    padding: 1.5rem;
    height: 6.5rem;
  }

  .hotlist-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .hotlist-title-text {
    font-size: 1rem;
  }

  .hotlist-logo {
    width: 3.5rem;
    height: 3.5rem;
  }

  .hotlist-container {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0 0;
    align-items: center;
  }

  .hotlist-js-title {
    font-size: 1.5rem;
  }

  .hotlist-js-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    width: -moz-available;
    margin: 0 1.5rem;
  }
}

.delete-recruiter-container {
  height: 29.875rem;
}
@mixin font-style($weight, $size, $lineHeight, $color) {
  font-weight: $weight;
  font-size: $size;
  line-height: $lineHeight;
  color: $color;
}
.delete-rec-heading {
  @include font-style(bold, 1.375rem, 1.875rem, #30374c);
}
.delete-rec-question {
  @include font-style(normal, 1rem, 1.375rem, #30374c);
}
.delete-rec-details-div {
  width: 21.25rem;
}
.delete-rec-label {
  @include font-style(normal, 0.875rem, 1.125rem, #626880);
}
.delete-rec-data {
  @include font-style(bold, 0.875rem, 1.125rem, #626880);
}
.delete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: ".125rem solid #000";
  box-shadow: 24;
  height: 29.875rem;
  width: 38.375rem;
  border-radius: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  margin: 1.5rem;
}

@import "../../scssPartials/sharedStyles";

.employer-card-wrapper {
  background: #fefefe;
  box-shadow: 0rem 0.25rem 1rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
  max-width: 18.625rem;
}

.employer-card-container {
  position: relative;
  padding-bottom: 1.625rem;
}

.employer-card-cover-image {
  margin: 0.5rem;
  min-height: 7.5625rem;
  height: 7.5625rem;
  border-radius: 1rem;
  width: -webkit-fill-available;
  width: -moz-available;
}

.employer-card-logo-container {
  position: absolute;
  top: 5.812em;
  left: 4.5rem;
  background: #ffffff;
  box-shadow: 0px 0px 1.25rem rgba(77, 108, 217, 0.16);
  border-radius: 1rem;
  height: 4.5rem;
  min-height: 4.5rem;
  width: 9.5rem;
}

.employer-card-logo {
  object-fit: contain;
  width: -webkit-fill-available;
  width: -moz-available;
  border-radius: 1rem;
  padding: 0 1rem;
  min-height: 4.5rem;
}

.employer-card-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.75rem 1.5rem 0 1.5rem;
}

.employer-card-follow-button {
  margin: 1.5rem 1.5rem 0 1.5rem;
  width: -webkit-fill-available;
  width: -moz-available;
}

.employer-card-name {
  @include font(1.375rem, 700, #475569, 1.8125rem);
}

.employer-card-rating-container {
  display: flex;
  align-items: center;
}

.employer-card-rating {
  @include font(0.8125rem, 700, #626880, 1.125rem);
  margin: 0.25rem;
}

.employer-card-reviews {
  @include font(0.8125rem, 600, #626880, 1.125rem);
}

.employer-card-icon-container {
  display: flex;
  align-items: center;
  margin-top: 1.5125rem;
  margin-left: 1.5rem;
}

.employer-card-icon-text {
  @include font(0.8125rem, 400, #30374c, 1.125rem);
  margin-left: 0.625rem;
}

.employer-card-icon-text-hover {
  cursor: pointer;
}

.recruiter-tabs-container {
  display: flex;
  justify-content: space-between;
  background: #4d6cd9;
  border-radius: 1rem 1rem 0 0;
  width: auto;
  margin-bottom: 1.5rem;
}

.recruiter-tabs-tab {
  margin: 0.5rem;
  flex-grow: 1;
}

.recruiter-tabs-active-tab {
  background: #f1f7ff;
  border-radius: 0.75rem;
}

.recruiter-tabs-text {
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  text-align: center;
  color: #ffffff;
  padding: 0.625rem 1.361875rem;
  cursor: pointer;
}

.recruiter-tabs-active-text {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #4d6cd9;
  cursor: default;
}

.recruiter-tabs-join-hh {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4d6cd9;
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 2rem 0;
}

.recruiter-tabs-join-hh-text {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #e9f0ff;
  margin-left: 2rem;
}

.recruiter-tabs-join-hh-button {
  margin-left: 2rem;
}

.recruiter-tabs-contests-metrics {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #4d6cd9;
  border-radius: 1rem;
  padding: 3rem 0;
  margin-bottom: 1.5rem;
}

.recruiter-tabs-contests-icon-container {
  display: flex;
}

.recruiter-tabs-contests-icon-text-container {
  margin-left: 1rem;
  color: #f8f9fe;
}

.recruiter-tabs-contests-icon-text {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #ffffff;
}

.recruiter-tabs-contests-icon-label {
  font-size: 1rem;
  line-height: 1.375rem;
}

.recruiter-tabs-hit-ratio-container {
  position: relative;
}

.recruiter-tabs-hit-ratio {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #30374c;
  position: absolute;
  text-align: center;
  top: 3.4375rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.recruiter-tabs-overall-stats-container {
  margin-bottom: 1.5rem;
}

.recruiter-tabs-overall-statistics-container {
  padding: 2.5rem 0 2.5rem 13.5rem;
}

.recruiter-tabs-overall-statistics {
  font-weight: bold;
  font-size: 1.1875rem;
  line-height: 1.625rem;
  color: #30374c;
}

.recruiter-tabs-overall-statistics-icon-container {
  display: flex;
  margin-top: 1.5rem;
}

.recruiter-tabs-overall-statistics-text-container {
  margin-left: 1rem;
}

.recruiter-tabs-overall-statistics-text {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #626880;
}

.recruiter-tabs-overall-statistics-label {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #30374c;
}

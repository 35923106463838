@import "../../scssPartials/sharedStyles";

.recruiter-profile-card-wrapper {
  height: 27.8125rem;
  overflow: hidden;
  transition: 3s;
  box-shadow: 0rem 0rem 5rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
}

.recruiter-profile-card-wrapper-hovered {
  z-index: 2;
  overflow: visible;
  box-shadow: 0rem 0rem 5rem rgba(206, 212, 240, 0.8);
}

.recruiter-profile-card-container {
  position: relative;
  padding: 5rem 1.5625rem 1.6875rem 1.5625rem;
  display: flex;
  flex-direction: column;
}

.recruiter-profile-card-profile-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recruiter-profile-card-name-container {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 1.0625rem;
  height: 2.5rem;
  text-overflow: ellipsis;
}

.recruiter-profile-card-name {
  @include font(1.0625rem, bold, #30374c, 1.25rem);
  text-align: center;
}

.recruiter-profile-card-location-container {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 2.75rem;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
}

.recruiter-profile-card-location {
  @include font(1rem, normal, #626880, 1.375rem);
  text-align: center;
}

.recruiter-profile-card-type {
  position: absolute;
  top: 1.5rem;
  @include font(1rem, bold, #626880, 1.375rem);
}

.recruiter-profile-card-profile-icons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.recruiter-profile-card-profile-icon-container {
  display: flex;
  align-items: flex-start;
}

.recruiter-profile-card-profile-icons-container-text {
  @include font(1rem, bold, #626880, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-card-profile-icons-container-label {
  @include font(0.875rem, normal, #626880, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-card-profile-icons-container-2 {
  margin-top: 1.5rem;
}

.recruiter-profile-card-stats-wrapper {
  margin-top: 1.0625rem;
  background: #e9f0ff;
  border-radius: 1rem;
  padding: 1.125rem 1rem 1.125rem 1.125rem;
}

.recruiter-profile-card-stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recruiter-profile-card-stats-container:not(:first-child) {
  margin-top: 1.1875rem;
}

.recruiter-profile-card-stats-image-container {
  display: flex;
  align-items: center;
}

.recruiter-profile-card-stats-image-text {
  margin-left: 0.75rem;
  @include font(0.875rem, normal, #626880, 1.125rem);
}

.recruiter-profile-card-stats-container-text {
  @include font(1rem, bold, #626880, 1.375rem);
}

.recruiter-profile-view-profile-button {
  margin-top: 1.0625rem;
  width: -webkit-fill-available;
  width: -moz-available;
}

.recruiter-profile-card-stats-container-icon:not(:last-child) {
  margin-right: 0.75rem;
}

.recruiter-profile-card-stats-container-tootltip {
  display: flex;
  align-items: center;
  padding: 0.625rem;
}

.recruiter-profile-card-stats-container-tootltip-text {
  @include font(1rem, normal, #30374c, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-card-container-share {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.recruiter-profile-card-container-share-menu-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.recruiter-profile-card-container-share-menu-item-text {
  @include font(1rem, normal, #30374c, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-card-profile-image {
  height: 6.25rem;
}

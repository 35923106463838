@import "../../scssPartials/sharedStyles";

.primary-color {
  color: #4d6cd9;
}
.secondary-color {
  color: #626880 !important;
}
.container {
  background-color: #fff;
}
.button {
  border-radius: 10px !important;
  border-color: #4d6cd9 !important;
  color: #4d6cd9 !important;
}
.button-primary {
  background-color: #4d6cd9 !important;
  color: #fff !important;
}

.hide-scroll-bar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.buttons {
  width: 400px;
  height: 45px;
  gap: 40px;
  margin-top: 40px;
}

.logos {
  width: 150px;
  height: 45px;
  object-fit: contain;
  gap: 20px;
}
.testimonial-logo {
  width: 250px;
  height: 245px;
  object-fit: contain;
}
.quote-reverse {
  height: 200px;
  width: 250px;
  position: absolute;
  z-index: 0;
  top: -10px;
  left: 50px;
}
.quote-straight {
  height: 200px;
  width: 250px;
  position: absolute;
  right: 50px;
  bottom: -20%;
  z-index: 0;
}
.testimonial-card {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  z-index: 9;
}
.testimonial-logo {
  width: 30px;
  height: 30px;
}

.forwardArrow {
  position: absolute;
  bottom: 50%;
  right: 50px;
  z-index: 10;
}

.reverseArrow {
  position: absolute;
  bottom: 50%;
  left: 50px;
  z-index: 10;
}

@media (max-width: 1060px) {
  .accelerating-hiring-images,
  .quote-reverse,
  .quote-straight {
    display: none;
  }
}

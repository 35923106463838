@import "../../scssPartials/sharedStyles";

.recruiter-filters-container {
  width: 16.625rem;
  padding: 1.1875rem 1rem 2rem 1rem;
  background: #ffffff;
}

.recruiter-filters-title-container {
  display: flex;
  align-items: center;
}

.recruiter-filters-title {
  @include font(1.0625rem, bold, #262626, 1.25rem);
  margin-left: 0.875rem;
}

.recruiter-filters-separator {
  border: 0.0625rem solid #dfe5ff;
  margin: 1.0625rem 0;
}

.recruiter-filters-clear-filters-button {
  margin-top: 1.5rem;
}

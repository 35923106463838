.heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem;
}

.heading-label {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.875rem;
  text-align: center;
  color: #262626;
}

.underline {
  position: static;
  width: 1.875rem;
  height: 0.25rem;
  left: 5.375rem;
  top: 0.875rem;
  background: #4a68d4;
  border-radius: 0.5rem;
  flex: none;
  flex-grow: 0;
  margin: 1rem 0rem;
}

.hotlist-card-wrapper {
  height: 26rem;
  overflow: hidden;
  transition: 3s;
  box-shadow: 0rem 0rem 5rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
  min-width: 15rem;
}

.hotlist-card-wrapper-hovered {
  z-index: 2;
  overflow: visible;
}

.hotlist-card-container {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hotlist-card-hovered-container {
  width: -webkit-fill-available;
  width: -moz-available;
}

.hotlist-card-details-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  width: -moz-available;
}

.hotlist-card-details {
  display: flex;
  justify-content: space-between;
  margin: 0.25rem 0;
}

.hotlist-card-separator {
  width: 100%;
  border-top: 0.0625rem solid #aab0cb;
}

.hotlist-card-id {
  color: var(--secondary-text-color);
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem 0 0.5rem 0;
}

.hotlist-card-wanted-image {
  margin-top: 1rem;
}

.hotlist-card-role {
  font-size: 1.125rem;
  margin-top: 1rem;
  font-weight: bold;
  height: 2.5rem;
}

.hotlist-card-hovered-text {
  margin-top: 0.25rem;
}

.hotlist-card-add-list-card {
  display: flex;
  align-items: center;
}

@media (max-width: 50rem) {
  .hotlist-card-wrapper {
    height: auto;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .hotlist-card-container {
    padding: 0 1rem;
  }

  .hotlist-card-id {
    color: var(--secondary-text-color);
    font-size: 1.125rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  .hotlist-card-role {
    font-size: 1rem;
  }

  .hotlist-card-details {
    font-size: 0.875rem;
  }
}

.rewards-container {
  background: #fefefe;
  box-shadow: 0rem 0.25rem 1rem rgba(206, 212, 240, 0.8);
  border-radius: 0rem 0rem 1rem 1rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}

.error-meesage {
  top: 10%;
  left: 40%;
  position: relative;
}

.service-fees-section {
  width: 56.9375rem;
  align-self: center;
  margin-top: 6rem;
}

.service-fees-title {
  font-size: 1.1875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.625rem;
  letter-spacing: 0em;
  text-align: left;
  color: #30374c;
  margin-bottom: 1.3125rem;
}

.service-fees-table {
  margin-top: 1.3125rem;
}

.rewards-desc-section {
  position: static;
  left: 7.5rem;
  top: 109.25rem;
  padding-left: 2rem;
  padding-top: 3.125rem;
}

.rewards-desc-title {
  position: static;
  height: 1.625rem;
  left: 7.5rem;
  top: 59.3125rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1875rem;
  line-height: 1.625rem;
  color: #30374c;
}

.rewards-desc-text {
  left: 7.5rem;
  top: 111.8125rem;
  padding-top: 1.25rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #626880;
}

.rewards-stats-container {
  display: flex;
  margin: 4rem 0 4.4375em 0;
  justify-content: space-evenly;
}

.rewards-stat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rewards-stat-image-background {
  height: 5rem;
  width: 5rem;
  left: 0rem;
  top: 0rem;
  border-radius: 1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0rem 0rem 1.25rem rgba(77, 108, 217, 0.16);
  border-radius: 1rem;
}

.rewards-stat-value {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.0625rem;
  color: #30374c;
  margin-top: 1rem;
}

.rewards-stat-text {
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  color: #626880;
}

@media only screen and (max-width: 780px) {
  .service-fees-section {
    width: 100%;
    margin-top: 1rem;
  }
  .service-fees-title {
    padding: 0.5rem;
  }
  .service-fees-table table {
    width: 100%;
    padding: 0.5rem;
  }
  .rewards-stats-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.75rem 1rem;
  }
}

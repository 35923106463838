.add-recruiter-button{
    width: 25.375rem;
}
.add-team-short-div {   
    width: 25.375rem;
    margin-top: 3.5rem;
    word-wrap: normal;
}
.add-team-add-recruiters{
    top: 1rem;
}
.add-team-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 25.375rem;
    /* height: 5.125rem; */
    height: auto;
    /* margin-top: 3.5rem; */
}
.delete-recruiter {
    height: 2.625rem;
    width: 2.625rem;
    left: 22.75rem;
    top: 0rem;
    border-radius: .75rem;
}
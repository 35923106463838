.input-size {
  width: 25.375rem;
}
.radio-height {
  height: 1.875rem;
}

.background-div-row-flex {
  width: 25.375rem;
  padding-top: 2.5rem;
}

.div-row-flex-short {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 25.375rem;
  height: 3.125rem;
}
.option-button {
  width: 11.9375rem;
}
.requisitions-textarea {
  height: 4rem;
}
.radiogroup-verticle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.next-button {
  height: 2.625rem;
  left: 13.4375rem;
  top: 0rem;
  border-radius: 0.75rem;
  padding: 0.75rem, 1.5rem, 0.75rem, 1.5rem;
}
.recruiter-label {
  color: var(--text-input-active-color);
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
}

/* .employers-title-container {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.employers-title {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #262626;
}

.employers-icon {
  cursor: pointer;
}

.employers-list-view-icon {
  margin-left: 1rem;
}

.employers-no-employers {
  margin-top: 10rem;
  margin-left: 15rem;
  font-size: 1.125rem;
  color: #000000;
}

.employers-employers-container {
  margin-top: 1.375rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(18.625rem, 1fr));
  grid-row-gap: 1.75rem;
  grid-column-gap: 1.5rem;
  grid-auto-flow: dense;
  align-items: start;
  margin-left: 1.5rem;
}

.employers-pagination-container {
  float: right;
  padding: 1.625rem 0 0 0;
  margin: 0;
}

.employers-per-page-container {
  display: flex;
  align-items: baseline;
  float: left;
  padding: 1.625rem 0 0 0;
  margin-top: 1.125rem;
}

.employers-per-page {
  width: 6.75rem;
}

.employers-per-page-text {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #626880;
}

.employers-list-card {
  margin-top: 1.375rem;
}

.employers-sort-container {
  display: flex;
  height: 2.625rem;
}

.employers-sort {
  margin-left: 1.75rem;
  width: 11.875rem;
  max-width: 11.875rem;
}

.employers-filter {
  margin-left: 1.75rem;
  width: 13.875rem;
  max-width: 13.875rem;
}

.employers-cards-container {
  display: flex;
}

.employers-list-card {
  display: flex;
  flex-direction: row;
  margin-left: 1.5rem;
}

.employers-filters {
  margin-top: 1.5rem;
}

.employers-cards-list-container {
  width: 100%;
}
 */

 .employers-container {
  padding: 1rem;
}

.employers-title-container {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.employers-title {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #262626;
}

.employers-icon {
  cursor: pointer;
  height: fit-content;
  margin: auto;
}

.employers-list-view-icon {
  margin-left: 1rem;
}

.employers-wrapper-container {
  display: flex;
  flex-direction: row;
}

.employers-filters-container {
  display: block;
  margin: 1.5rem 1.5rem 0 0;
}

.employers-employers-container {
  margin-top: 1.375rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(18.625rem, 1fr));
  grid-row-gap: 1.75rem;
  grid-column-gap: 1.5rem;
  grid-auto-flow: dense;
  align-items: start;
}

.filters-button {
  display: none;
}

.hidden-mobile {
  display: block;
}

.employers-pagination-container {
  float: right;
  padding: 1.625rem 0 0 0;
  margin: 0;
}

.employers-list-card {
  margin-top: 1.375rem;
  width: 100%;
}

.employers-sort-container {
  display: flex;
  height: 2.625rem;
  align-items: center;
}

.employers-sort {
  margin-left: 1.75rem;
  width: 130px;
  max-width: 11.875rem;
}

.employers-no-employers {
  margin-top: 10rem;
  font-size: 1.125rem;
  color: #000000;
  text-align: center;
}

@media only screen and (max-width: 740px) {
  .employers-title-container {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .employers-title {
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  .hidden-mobile {
    display: none;
  }

  .filters-button {
    display: block;
    margin-top: 1rem;
  }

  .employers-wrapper-container {
    flex-direction: column;
  }

  .employers-filters-container {
    display: none;
    height: 40%;
  }

  .employers-employers-container {
    grid-template-columns: repeat(1, minmax(13.625rem, 1fr));
  }

  .employers-pagination-container {
    float: none;
    justify-content: center;
    padding: 1.625rem 0 0 0;
  }

  .employers-sort {
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
  }
}

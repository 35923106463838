.overview-container {
  background: #ffffff;
  display: flex;
}

.overview-title {
  font-weight: bold;
  font-size: 1.1875rem;
  line-height: 1.625rem;
  color: #30374c;
}

.overview-description {
  font-size: 1rem;
  line-height: 1.375rem;
  max-width: 43.4375rem;
  margin-top: 1rem;
}

.overview-domain-wrapper {
  margin-left: 8.25rem;
}

.overview-domain-container {
  display: flex;
  margin-top: 1.25rem;
  flex-wrap: wrap;
}

@media only screen and (max-width: 780px) {
  .overview-description {
    width: 100%;
  }
  .overview-container {
    display: block;
  }
  .overview-domain-wrapper {
    margin-top: 1rem;
    margin-left: 0px;
  }
}

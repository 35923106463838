.employer-tabs-container {
  display: flex;
  justify-content: space-between;
  background: #4d6cd9;
  border-radius: 1rem;
  width: auto;
}

.employer-tabs-tab {
  margin: 0.5rem;
  flex-grow: 1;
}

.employer-tabs-active-tab {
  background: #f1f7ff;
  border-radius: 12px;
}

.employer-tabs-text {
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  text-align: center;
  color: #ffffff;
  padding: 0.625rem 1.361875rem;
  cursor: pointer;
}

.employer-tabs-active-text {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #4d6cd9;
  cursor: default;
}

.employer-tab-container {
  background: #fefefe;
  box-shadow: 0px 0.25rem 1rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
  margin-top: 1.5rem;
  padding: 2.5rem 6.6875rem;
}

.employer-tabs-metrics {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #4d6cd9;
  border-radius: 1rem;
  padding: 3rem 0;
  margin: 1.5rem 0;
}

.employer-tabs-icon-container {
  display: flex;
}

.employer-tabs-icon-text-container {
  margin-left: 1rem;
  color: #f8f9fe;
}

.employer-tabs-icon-text {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #ffffff;
}

.employer-tabs-icon-label {
  font-size: 1rem;
  line-height: 1.375rem;
  max-width: 10.875rem;
}

.employer-tabs-details-container {
  margin-top: 1.5rem;
}

.employer-tabs-no-social-media-handles {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #30374c;
}

.employer-tabs-social-media-handle-title {
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #30374c;
  margin-bottom: 2.5rem;
}

.employer-tabs-social-media-handle-section-title {
  font-weight: 700;
  font-size: 1.1875rem;
  line-height: 1.625rem;
  color: #30374c;
  margin-bottom: 1.5rem;
}

.employer-tabs-social-media-handle-stats-container {
  display: flex;
  align-items: center;
}

.employer-tabs-social-media-handle-stat-container {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.employer-tabs-social-media-handle-stat-count-container {
  margin-left: 1rem;
}

.employer-tabs-social-media-handle-stat-count {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #626880;
}

.employer-tabs-social-media-handle-stat-text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #30374c;
}

.employer-tabs-social-media-handle-separator {
  border: 0.0625rem solid #dfe5ff;
  margin: 1.625rem 0 1.5rem 0;
}

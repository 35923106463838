.textfield-wrapper .active-phone {
  color: var(--text-input-active-color);
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  transform: translate(0, -1.375rem) scale(1);
}

.textfield-wrapper .phone {
  transform: translate(6.35rem, 0.5rem) scale(1);
}

.div-phone-number {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 25.375rem;
}

@import "../../scssPartials/sharedStyles";

.recruiter-contest-container {
  display: flex;
  position: relative;
  padding-top: 1.5rem;
}

.recruiter-contest-stats-container {
  margin-top: 0.9375rem;
  margin-left: 1.5rem;
}

.recruiter-contest-end-date {
  position: absolute;
  top: 0;
  @include font(1rem, normal, #626880, 1.375rem);
}

.recruiter-contest-company-details-container {
  display: flex;
  margin-top: 1.5rem;
}
.recruiter-contest-logo-container {
  width: 8rem;
  height: 4rem;
  background: #ffffff;
  box-shadow: 0px 0px 1.25rem rgba(77, 108, 217, 0.16);
  border-radius: 1rem;
  padding: 0 0.625rem;
}

.recruiter-contest-logo-container img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.recruiter-contest-company-container {
  margin-left: 0.75rem;
  align-self: center;
}

.recruiter-contest-company-name {
  @include font(1.125rem, bold, #30374c, 1.625rem);
}

.recruiter-contest-contest-title {
  @include font(1rem, bold, #626880, 1.375rem);
  margin-top: 0.25rem;
}

.recruiter-contest-metrics-container {
  margin-top: 1.5rem;
  background: #f1f7ff;
  border-radius: 1rem;
  padding: 1rem 3.375rem 0.875rem 1.125rem;
}

.recruiter-contest-metrics-icons-container {
  display: flex;
}

.recruiter-contest-metrics-icon-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.recruiter-contest-metrics-icon-right-container {
  margin-left: 1.411875rem;
}

.recruiter-contest-metrics-icon-text-container {
  margin-left: 0.625rem;
}

.recruiter-contest-metrics-icon-text {
  @include font(1rem, bold, #626880, 1.375rem);
}

.recruiter-contest-metrics-icon-label {
  @include font(0.875rem, normal, #626880, 1.375rem);
}

.recruiter-contest-metrics-separator {
  border: 0.0625rem solid #dfe5ff;
  margin-bottom: 1.5rem;
}

@import "../../scssPartials/sharedStyles";
.multi-color-text {
  @include font(1.875rem, bold, null, 2.5rem);
  padding: 0.2125rem;
  text-align: center;
}

@media only screen and (max-width: 720px) {
  .multi-color-text {
    font-size: 1.5rem;
  }

  .multi-color-title-div {
    text-align: center;
  }
}

@import "../../scssPartials/sharedStyles";
.our-commitment-container {
  height: 80rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.our-commitment-topics {
  @include display-align(row, space-around, center);
  width: 70rem;
  height: 30.5rem;
  padding: 0 5rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 1.25rem rgba(77, 108, 217, 0.16);
  border-radius: 1rem;
}

.our-commitment-item-container {
  @include display-align(column, null, center);
  width: 20rem;
  height: 19.1875rem;
}

.our-commitment-icon {
  background: #ffffff;
  border-radius: 1rem;
}

.our-commitment-topic {
  @include font(1.5rem, bold, #626880, 2.0625rem);
  text-align: center;
  padding-bottom: 1.5rem;
}

.our-commitment-desc {
  @include font(1rem, normal, #626880, 1.375rem);
  text-align: center;
  padding-bottom: 1.5rem;
}

.our-commitment-tiles {
  width: 59.25rem;
  padding-top: 5.5rem;
}
.our-commitment-grid {
  @include display-align(column, null, center);
}

@media only screen and (max-width: 720px) {
  .our-commitment-container {
    height: 700rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    top: -1rem;
  }
  .our-commitment-tiles {
    width: 100vw;
    margin: auto;
  }
  .custom-grid {
    margin: auto !important;
  }
  .our-commitment-topics {
    height: 100%;
    display: flex;
    flex-direction: column !important;
    width: 21rem;
  }
  .mobile-grid-style {
    display: flex;
    flex-direction: column !important;
    margin: auto;
  }
}

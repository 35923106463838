.accordion-container {
  background: #fefefe;
  box-shadow: 0 0.25rem 1rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
  border: 0.0625rem solid #aab0cb;
  padding: 1.53125rem 1.5rem 1.53125rem 1.5rem;
  position: relative;
  cursor: pointer;
}

.accordion-expanded-container {
  background: #fefefe;
  border: 0.09375rem solid #ced4f0;
}

.accordion-title {
  font-size: 1.0625rem;
  line-height: 1.375rem;
  color: #30374c;
  margin-right: 1.5rem;
  font-weight: 600;
}

.accordion-expand-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.375rem;
  cursor: pointer;
}

.accordion-children {
  margin-top: 1.59375rem;
}

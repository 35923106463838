.partner-container {
  display: flex;
  flex-direction: column;
}

.partner-logo-container {
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0 0 1.25rem rgba(77, 108, 217, 0.16);
  width: 9rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
}

.partner-logo {
  margin: auto;
  display: block;
  align-self: center;
}

.partner-name {
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  color: #30374c;
  text-align: center;
  margin-top: 1.5rem;
}

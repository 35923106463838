@import "../../scssPartials/sharedStyles";

.share-icon {
  cursor: pointer;
}

.share-menu {
  display: block;
}

.share-menu-item-container {
  padding: 0.5rem 0.75rem !important;
}

.share-menu-item {
  display: flex;
  align-items: center;
}

.share-menu-item-text {
  @include font(1rem, normal, #30374c, 1.375rem);
  margin-left: 0.625rem;
}

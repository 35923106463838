@import "../../scssPartials/sharedStyles";

.checkbox-filter-container {
  display: flex;
  justify-content: space-between;
}

.checkbox-filter-filter-title {
  @include font(1rem, bold, #30374c, 1.375rem);
}

.checkbox-filter-expand-icon {
  cursor: pointer;
}

.checkbox-filter-filters {
  margin-top: 1rem;
  padding: 0.5rem;
  @include border(1.5px solid #e9f0ff, 0.5rem);
  max-height: 13.5rem;
  overflow-y: scroll;
}

.checkbox-filter-filter {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.checkbox-filter-filter-selected {
  background: #e9f0ff;
  border-radius: 0.5rem;
}

.checkbox-filter-filter-name {
  @include font(1rem, normal, #30374c, 1.375rem);
}

@import "../../scssPartials/sharedStyles";

.recruiter-stats-container {
  display: flex;
  position: relative;
}

.recruiter-stats-funnel {
  position: absolute;
  left: 109px;
}

@mixin recruiter-stats-percentage-div {
  border-right: none;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@mixin recruiter-stats-stats-div {
  border-left: none;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
}

.recruiter-stats-percentage-profiles-submitted {
  border: 0.125rem solid #cd433e;
  @include recruiter-stats-percentage-div();
  height: 3.25rem;
  width: 5.9375rem;
}

.recruiter-stats-percentage-profiles-shortlisted {
  border: 0.125rem solid #edd85f;
  @include recruiter-stats-percentage-div();
  height: 3.25rem;
  width: 11.25rem;
  position: absolute;
  top: 4.8875rem;
}

.recruiter-stats-percentage-offer-rolled-out {
  border: 0.125rem solid #5dacc8;
  @include recruiter-stats-percentage-div();
  height: 3.25rem;
  width: 15.5rem;
  position: absolute;
  top: 9.875rem;
}

.recruiter-stats-percentage-joined {
  border: 0.125rem solid #4a779f;
  @include recruiter-stats-percentage-div();
  height: 3.25rem;
  width: 15.5rem;
  position: absolute;
  top: 14.8125rem;
}

.recruiter-stats-stats-profiles-submitted {
  border: 0.125rem solid #cd433e;
  @include recruiter-stats-stats-div();
  height: 3.25rem;
  width: 5.9375rem;
  left: 34.0625rem;
}

.recruiter-stats-stats-profiles-shortlisted {
  border: 0.125rem solid #edd85f;
  @include recruiter-stats-stats-div();
  height: 3.25rem;
  width: 8.9375rem;
  top: 4.8875rem;
  left: 31.2625rem;
}

.recruiter-stats-stats-offer-rolled-out {
  border: 0.125rem solid #5dacc8;
  @include recruiter-stats-stats-div();
  height: 3.25rem;
  width: 14.1875rem;
  top: 9.875rem;
  left: 26.0625rem;
}

.recruiter-stats-stats-joined {
  border: 0.125rem solid #4a779f;
  @include recruiter-stats-stats-div();
  height: 3.25rem;
  width: 14.1875rem;
  top: 14.8125rem;
  left: 26.0625rem;
}

.recruiter-stats-precentage-text {
  @include font(1.5rem, bold, #30374c, 2.0625rem);
  padding-top: 0.625rem;
  padding-left: 0.906875rem;
}

.recruiter-stats-stats-text {
  @include font(1.5rem, bold, #30374c, 2.0625rem);
  text-align: end;
  padding-top: 0.625rem;
  padding-right: 0.906875rem;
}

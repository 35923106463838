@mixin onboard-select-position($width, $height, $left, $top) {
  width: $width;
  height: $height;
  left: $left;
  top: $top;
}

@mixin onboard-select-display(
  $display,
  $direction,
  $alignItems,
  $justifyContent
) {
  display: $display;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin onboard-select-size($width, $height) {
  width: $width;
  height: $height;
}

.onboard-selection-container {
  position: static;
  padding: 0rem;
  @include onboard-select-position(16.0625rem, 11.4375rem, 0rem, 3.4375rem);
  @include onboard-select-display(flex, row, center, space-between);
}

.onboard-option-group {
  @include onboard-select-size(5.6875rem, 11.4375rem);
  @include onboard-select-display(flex, column, center, center);
}

.onboard-option-checkbox {
  @include onboard-select-size(1.875rem, 1.875rem);
  top: 0rem;
}

.onboard-option-div {
  @include onboard-select-size(5rem, 5rem);
  @include onboard-select-display(flex, column, center, center);
  border: 0.125rem solid #dfe5ff;
  box-sizing: border-box;
  border-radius: 1rem;
  background: #ffffff;
  &:hover {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    box-shadow: 0rem 0rem 1.25rem 0rem #4d6cd929;
    border: 0.125rem solid #ffffff;
  }
  &:active {
    border: 0.125rem solid #4d6cd9;
  }
}

.onboard-option-div-active {
  @extend .onboard-option-div;
  border: 0.125rem solid #4d6cd9;
}

.onboard-option-image {
  @include onboard-select-size(3.0625rem, 2.6575rem);
  path {
    fill: #9ba9e8;
  }
  &:active {
    path {
      fill: #4d6cd9;
    }
  }
}

.onboard-option-image-active {
  @extend .onboard-option-image;
  path {
    fill: #4d6cd9;
  }
}

.onboard-option-label {
  @include onboard-select-position(5.6875rem, 2.75rem, 0rem, 6rem);
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
  color: #626880;
}

.onboard-option-label-active {
  @extend .onboard-option-label;
  font-weight: bold;
  color: #30374c;
}

@mixin step-size($width, $height) {
  width: $width;
  height: $height;
}

@mixin step-align($left, $top) {
  left: $left;
  top: $top;
}

@mixin step-text-style($fontWeight, $fontSize, $lineHeight, $textAlign) {
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
  text-align: $textAlign;
}

@mixin step-display($display, $direction, $justifyContent, $alignItems) {
  display: $display;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin set-margin-left($margin-left) {
  margin-left: $margin-left;
}

.breadcrumbs {
  position: absolute;
  @include step-display(flex, row, space-between, center);
}

.step {
  position: relative;
  @include step-size(2.5rem, 2.5rem);
  padding: 0;
  z-index: 1;
  border-radius: 0.75rem;
  @include step-display(flex, row, space-around, center);
}

.step-active {
  @extend .step;
  background: #4d6cd9;
  &:active {
    background: #1749c7;
    border: solid 0.0625rem #ffffff;
    outline: solid 0.0625rem #1749c7;
    border-radius: 0.875rem;
  }
}

.step-not-done {
  @extend .step;
  background: #ffffff;
  border: 0.09375rem solid #ced4f0;
  box-sizing: border-box;
}

.step-done {
  @extend .step;
  background: #00c15d;
  &:active {
    background: #008d3a;
    border: solid 0.0625rem #ffffff;
    outline: solid 0.0625rem #008d3a;
    border-radius: 0.875rem;
  }
}

.step-number {
  @include step-text-style(bold, 0.875rem, 1.375rem, center);
}

.step-number-active {
  @extend .step-number;
  color: #f1f7ff;
}

.step-number-done {
  @extend .step-number;
  color: #ffffff;
}

.step-number-not-done {
  @extend .step-number;
  color: #626880;
}

.step-track {
  position: absolute;
  @include step-size(8rem, 0.125rem);
  padding-left: 2rem;
  z-index: 0;
  background: #ced4f0;
}

.step-track-3 {
  @extend .step-track;
  @include set-margin-left(3rem);
}

.step-track-4 {
  @extend .step-track;
  @include set-margin-left(1.2rem);
}

.step-track-active {
  @extend .step-track;
  background: #4d6cd9;
}

.step-track-invisible {
  @extend .step-track;
  background: #ffffff;
  z-index: 0;
}

.step-track-done {
  @extend .step-track;
  background: #00c15d;
}

.step-name {
  @include step-size(5.375rem, 2.25rem);
  @include step-text-style(bold, 0.875rem, 1.125rem, center);
}

.step-name-done {
  @extend .step-name;
  color: #626880;
}

.step-name-active {
  @extend .step-name;
  color: #4d6cd9;
}

.step-name-not-done {
  @extend .step-name;
  color: #8a90a9;
  font-weight: normal;
}

.step-name-div {
  margin-top: 0.375rem;
}

.step-button {
  @include step-display(flex, column, center, center);
}

.step-tracker-div {
  @include step-display(flex, row, flex-start, flex-start);
  width: auto;
}

.step-block-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@import "../../scssPartials/sharedStyles";

.slider-filter-container {
  display: flex;
  justify-content: space-between;
}

.slider-filter-filter-title {
  @include font(1rem, bold, #30374c, 1.375rem);
}

.slider-filter-expand-icon {
  cursor: pointer;
}

.slider-filter-filters {
  margin-top: 1rem;
  padding-left: 1.2rem;
  background: #f1f7ff;
  border-radius: 0.5rem;
}

.text-input {
	&:disabled {
		border: 0.125rem solid var(--text-input-disabled-border);
		color: var(--text-input-disabled-color);
	}
	&:focus {
		border: 0.125rem solid var(--text-input-active-border);
		color: var(--text-input-actve-color);
		outline: none;
	}
}
.text-input{
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.5rem 0.75rem;
	position: static;
	width: 12.5rem;
	height: 2.625rem;
	left: 0rem;
	top: 0rem;
	background: #ffffff;
	border: 0.125rem solid var(--text-input-default-border);
	box-sizing: border-box;
	border-radius: 0.75rem;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.375rem;
	color: var(--text-input-default-color);
	outline: none;
}

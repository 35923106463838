@import "../../scssPartials/sharedStyles";
.core-values-container {
  background: #4d6cd9;
  border-radius: 1.5rem;
  width: 64rem;
  @include display-align(row, space-between, center);
  padding: 5.5rem 8.875rem 5.5625rem 6.125rem;
}
.core-values-image {
  width: 32.875rem;
}
.core-values-text-container {
  @include display-align(column, space-between, flex-start);
  width: 25.375rem;
  height: 10.625rem;
}
.core-values-text-desc {
  @include font(1rem, 700, #dfe5ff, 1.375rem);
  display: flex;
  align-items: center;
  width: 22.75rem;
}
.core-values-title {
  @include display-align(column, space-between, flex-start);
  height: 3.5rem;
}

@media only screen and (max-width: 720px) {
  .core-values-container {
    display: inline;
    text-align: center;
  }
  .core-values-text-container {
    align-items: center;
    width: 100%;
    position: relative;
    left: 1.5rem;
    margin-bottom: 1rem;
  }
  .core-values-image {
    width: 27.8rem;
    position: relative;
    left: 1.2rem;
    top: 1.5rem;
  }
}

.contest-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contest-detail-job-posted {
  position: absolute;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  margin-left: 1.5rem;
  color: #aab0cb;
}

.contest-detail-wanted-image {
  margin: 0 auto;
  display: block;
}

.contest-detail-type-image {
  display: block;
  object-fit: contain;
  width: -webkit-fill-available;
  width: -moz-available;
  position: relative;
  margin: 1rem 1rem 0 1rem;
}

.contest-detail-job-type {
  position: absolute;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 800;
  color: #ffffff;
  height: 1.375rem;
  text-align: center;
  top: 13.15rem;
}

.contest-detail-job-title {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: bold;
  color: var(ondary-text-color);
  padding: 0.375rem 0;
  text-align: center;
}

.contest-detail-cash-reward {
  margin-top: 1.5rem;
}

.contest-detail-company-experience-container {
  display: flex;
  margin-top: 1.5625rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.contest-detail-company-experience-container p {
  margin-left: 0.25rem;
  font-size: 0.8125rem;
  line-height: 1.125rem;
}

.contest-detail-company-container {
  display: flex;
  align-items: center;
  width: 9.5rem;
  max-width: 9.5rem;
}

.contest-detail-experience-container {
  display: flex;
  align-items: center;
  margin-left: 0.6875rem;
}

.contest-detail-experience-container p {
  margin-left: 0.5rem;
}

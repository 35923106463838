.onboard-wrapper {
  width: 65.625rem;
  height: 45.25rem;
}

.onboard-container {
  display: flex;
}
.onboard-card-container-height {
  height: 45.25rem;
}

@mixin onboard-display($display, $direction, $justifyContent, $alignItems) {
  display: $display;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin onboard-size-placement($position, $width, $height, $left, $top) {
  position: $position;
  width: $width;
  height: $height;
  left: $left;
  top: $top;
}
.onboard-main-container {
  @include onboard-display(flex, column, center, center);
  @include onboard-size-placement(
    absolute,
    17.3125rem,
    32.5rem,
    41.1875rem,
    4.8125rem
  );
}

.onboaard-top-container {
  @include onboard-display(flex, column, center, center);
  @include onboard-size-placement(static, 17.3125rem, 27.75rem, 0rem, 0rem);
}

.onboard-form {
  @include onboard-display(flex, column, center, center);
  @include onboard-size-placement(
    static,
    16.0625rem,
    21.5625rem,
    0.625rem,
    0rem
  );
}

.we-describe {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.4375rem;
  letter-spacing: 0em;
  text-align: left;
  color: #30374c;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 2rem 0rem;
}

.onboard-form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

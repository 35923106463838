@import "../../scssPartials/sharedStyles";

.recruiter-profile-list-card-wrapper {
  box-shadow: 0rem 0rem 5rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
  width: fit-content;
}

.recruiter-profile-list-card-container {
  position: relative;
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  cursor: pointer;
}

.recruiter-profile-list-card-container-avatar {
  width: 6.25rem;
  height: 6.25rem;
}

.recruiter-profile-list-card-profile-details-container {
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  padding-right: 1.6875rem;
  border-right: 0.0625rem solid #dfe5ff;
  width: 17.5625rem;
}

.recruiter-profile-list-card-name {
  @include font(1.0625rem, bold, #30374c, 1.25rem);
}

.recruiter-profile-list-card-location {
  @include font(1rem, normal, #626880, 1.375rem);
}

.recruiter-profile-list-card-type {
  @include font(1rem, bold, #626880, 1.375rem);
  margin-top: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid #dfe5ff;
}

.recruiter-profile-list-card-profile-icons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.recruiter-profile-list-card-profile-icon-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1rem;
}

.recruiter-profile-list-card-profile-icon-image-container {
  display: flex;
}

.recruiter-profile-list-card-profile-icons-container-text {
  @include font(0.875rem, bold, #626880, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-list-card-profile-icons-container-label {
  @include font(0.875rem, normal, #626880, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-list-card-stats-wrapper {
  margin-left: 1.5rem;
  background: #e9f0ff;
  border-radius: 1rem;
  padding: 1.125rem 1rem 1.125rem 1.125rem;
  width: 25rem;
}

.recruiter-profile-list-card-stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recruiter-profile-list-card-stats-container:not(:first-child) {
  margin-top: 1.1875rem;
}

.recruiter-profile-list-card-stats-image-container {
  display: flex;
  align-items: center;
}

.recruiter-profile-list-card-stats-image-text {
  margin-left: 0.75rem;
  @include font(0.875rem, normal, #626880, 1.125rem);
}

.recruiter-profile-list-card-stats-container-text {
  @include font(1rem, bold, #626880, 1.375rem);
}

.recruiter-profile-list-card-view-profile-button {
  margin-top: 1.0625rem;
  margin-left: 1.5rem;
  width: -webkit-fill-available;
}

.recruiter-profile-list-card-stats-container-icon:not(:last-child) {
  margin-right: 0.75rem;
}

.recruiter-profile-list-card-stats-container-tootltip {
  display: flex;
  align-items: center;
  padding: 0.625rem;
}

.recruiter-profile-list-card-stats-container-tootltip-text {
  @include font(1rem, normal, #30374c, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-list-card-container-share {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.recruiter-profile-list-card-container-share-menu-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.recruiter-profile-list-card-container-share-menu-item-text {
  @include font(1rem, normal, #30374c, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-list-card-container-active-since {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  @include font(0.875rem, bold, #aab0cb, 1.125rem);
}

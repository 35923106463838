@import "../../scssPartials/sharedStyles";

.recruiter-certificates-container {
  margin: 2.625rem 6.6875rem;
}

.recruiter-certificates-title-container {
  display: flex;
  justify-content: space-between;
}

.recruiter-certificates-title {
  @include font(1.875rem, bold, #30374c, 2.5rem);
}

.recruiter-certificates-sort {
  max-width: 12.5rem;
}

.recruiter-certificates-certificates-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(32.0625rem, 1fr));
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
}

.recruiter-certificates-certificate {
  width: 33.5625rem;
}

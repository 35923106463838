@import "../../scssPartials/sharedStyles";

@mixin card-size($height, $width) {
  height: $height;
  width: $width;
}

.info-card {
  @include display-align(column, space-evenly, center);
  @include card-size(20rem, 18.625rem);
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 1.25rem rgba(77, 108, 217, 0.16);
}

.info-card-content {
  @include display-align(column, space-evenly, center);
}

.info-card-icon-container {
  @include card-size(5rem, 5rem);
  @include display-align(column, center, center);
}

.info-card-title {
  @include font(1.0625rem, bold, #626880, 1.25rem);
  text-align: center;
}

.info-card-desc {
  @include font(1rem, normal, #626880, 1.375rem);
  text-align: center;
  margin: 0 1.375rem;
}

@media only screen and (max-width: 720px) {
  .info-card-homepage {
    width: 20rem;
    position: relative;
    left: 20rem;
    margin: 3rem 0;
  }
}

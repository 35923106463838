@import "../../scssPartials/sharedStyles";

.about-recruiter-container {
  @include flex-row-container();
  padding: 2.8125rem 6.6875rem;
}

.about-recruiter-description {
  @include font(1.0625rem, normal, #30374c, 1.25rem);
  width: 27.25rem;
  min-width: 27.25rem;
  margin-right: 2rem;
}

.about-recruiter-specialization {
  border-left: 0.0625rem solid #aab0cb;
  padding-left: 2rem;
}

.about-recruiter-hire-for {
  @include font(1.1875rem, bold, #0f172a, 1.625rem);
}

.about-recruiter-icons-container {
  display: flex;
  flex-direction: column;
}

.about-recruiter-icon-container {
  display: flex;
  align-items: flex-start;
  margin-top: 1.625rem;
}

.about-recruiter-icon-image-container {
  display: flex;
  min-width: 9.5rem;
}

.about-recruiter-icons-container-text {
  @include font(1rem, bold, #626880, 1.375rem);
}

.about-recruiter-icons-container-label {
  @include font(1rem, normal, #626880, 1.375rem);
  margin-left: 0.625rem;
}

@import "../../scssPartials/sharedStyles";

.contest-icons-conatiner {
  display: flex;
}

.contest-icon-conatiner {
  display: flex;
  align-items: center;
}

.contest-icon-conatiner:not(:last-child) {
  margin-right: 3.145rem;
}

.contest-icons-text {
  @include font(0.8125rem, bold, #626880, 1.125rem);
  margin-left: 0.5rem;
}

.contest-icon-clickable {
  cursor: pointer;
}

.what-makes-us-different-slide {
  height: 24.5rem;
  width: 104.5rem;
}

@media only screen and (max-width: 720px) {
  .what-makes-us-different-slide {
    height: 10rem;
    width: 30rem;
    position: relative;
    left: -3rem;
    top: -4rem;
  }
  .slider-wrapper {
    padding: 9rem 0;
  }
}

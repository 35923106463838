.contest-metrics-title {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #30374c;
}

.contest-metrics-icon-container {
  display: flex;
  justify-content: space-evenly;
  padding: 1.5625rem 2.375rem 1.875rem 2.375rem;
}

.contest-metric-icon {
  display: flex;
  flex-direction: column;
  color: #30374c;
}

.contest-metrics-icon-value {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.75rem;
  align-self: center;
  margin-top: 0.75rem;
}

.contest-metrics-icon-text {
  font-size: 1rem;
  line-height: 1.375rem;
  align-self: center;
}

.contest-metrics-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.contest-metrics-title {
  font-style: normal;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.875rem;
  padding: 2.125rem 0 0 2rem;
  color: #30374c;
}

@media only screen and (max-width: 780px) {
  .contest-metrics-icon-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.75rem 1rem;
  }
}

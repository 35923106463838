@import "../../scssPartials/sharedStyles";
table {
  width: 56.9375rem;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

th {
  height: 3.5rem;
  background: #4d6cd9;
  border-right: 0.125rem solid #dfe5ff;
}

th:first-child {
  border-radius: 1rem 0rem 0rem 0rem;
}

th:last-child {
  border-radius: 0rem 1rem 0rem 0rem;
  border-right: none;
}

th:only-child {
  border-radius: 1rem 1rem 0rem 0rem;
}

tr:nth-child(odd) {
  height: 3.5rem;
  top: 3.5rem;
  background: #f1f7ff;
}

tr:nth-child(even) {
  height: 3.5rem;
  top: 3.5rem;
  background: #fefefe;
}

tbody tr:last-child td {
  border-bottom: 0.125rem solid #dfe5ff;
}

tbody tr td {
  border: none;
  border-top: 0.125rem solid #dfe5ff;
  border-right: 0.125rem solid #dfe5ff;
}

tbody td:last-child {
  border-right: 0.125rem solid #dfe5ff;
}
tbody td:first-child {
  border-left: 0.125rem solid #dfe5ff;
}

tbody tr:last-child td:first-child {
  border-radius: 0 0 0 1rem !important;
}
tbody tr:last-child td:last-child {
  border-radius: 0 0 1rem 0 !important;
}

.table-text {
  @include font(1.1875rem, normal, #4f556c, 1.625rem);
  padding-left: 0.75rem;
}

.header-text {
  @include font(1rem, bold, #ffffff, 1.375rem);
  text-transform: capitalize;
  text-align: left;
  padding-left: 0.75rem;
}

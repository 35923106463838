@import "../../scssPartials/sharedStyles";

@mixin display-align-content($flexDirection, $justifyContent, $alignContent) {
  display: flex;
  flex-direction: $flexDirection;
  justify-content: $justifyContent;
  align-content: $alignContent;
}

@mixin size-position($height, $width, $left, $top) {
  height: $height;
  width: $width;
  left: $left;
  top: $top;
}
.form-container {
  @include display-align(column, space-between, center);
  align-content: center;
  padding-top: 6rem;
  padding-bottom: 2rem;
}

.company-details-height {
  height: 144rem;
}

.div-breadcrumb {
  @include display-align(column, center, center);
}

.skip {
  position: absolute;
  width: 4.125rem;
  height: 2.625rem;
  right: 3rem;
}

.div-form {
  @include display-align(column, space-between, center);
  width: 25.375rem;
  padding-top: 5rem;
}

.div-row-flex {
  @include display-align-content(row, space-between, center);
  width: 25.375rem;
  padding-top: 2.5rem;
}

.div-row-flex-short {
  @include display-align(row, flex-end, center);
  width: 25.375rem;
  height: 3.125rem;
}

.div-multi-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.div-padding-bottom {
  padding-bottom: 2rem;
}

.div-browse {
  @include display-align(column, space-between, flex-start);
  width: 25.375rem;
  height: 3.125rem;
  margin-top: 1.625rem;
}

.input-size {
  width: 25.375rem;
}
.div-placeholder {
  width: 25.375rem;
}
.name-field {
  width: 11.9375rem;
}
.phone-ext-field {
  width: 4.8125rem;
}
.phone-number-field {
  width: 19.8125rem;
}
.div-submit-button {
  @include display-align(row, center, center);
  width: 25.375rem;
  height: 5.125rem;
}
.submit {
  @include display-align(row, center, center);
  text-align: center;
  width: 15.625rem;
  padding: 0.71875rem 1.5rem;
}

.mandatory {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #626880;
}

.sub-heading {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.875rem;
  letter-spacing: 0em;
  text-align: left;
  color: #30374c;
}

@mixin display-align(
  $flexDirection,
  $justifyContent,
  $alignItems,
  $alignContent
) {
  display: flex;
  flex-direction: $flexDirection;
  justify-content: $justifyContent;
  align-items: $alignItems;
  align-content: $alignContent;
}

.onboarding-form-container {
  @include display-align(column, center, center, center);
  z-index: 999;
}

.onboarding-fields-container {
  @include display-align(column, center, null, null);
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 25rem;
}

.onboarding-heading-div {
  padding-top: 5.625rem;
}

.onboarding-div-field {
  padding-bottom: 2.5rem;
  @include display-align(row, space-evenly, null, center);
}

.onboarding-div-multi-field {
  @include display-align(row, space-between, null, center);
}

.onboard-as-dropdown {
  width: 25.375rem;
}

@mixin input-style($width) {
  width: $width;
  border-width: 0.09375rem;
  font-size: 1rem;
  border-radius: 0.75rem;
}
.name-input {
  position: static;
  @include input-style(11.9375rem);
}

.email-input {
  @include input-style(25.375rem);
}

.phone-prefix-input {
  @include input-style(4.8125rem);
}

.phone-number-input {
  @include input-style(19.8125rem);
}

.info-dropdown {
  position: static;
  width: 25.375rem;
}

.dropdown-label {
  padding-left: 1rem;
}

.disclaimer-and-submit-container {
  width: 25.375rem;
  height: 11.5rem;
  @include display-align(column, space-evenly, null, null);
}

.disclamer-info-container {
  height: 1rem;
  display: flex;
  flex-direction: row;
}

.onboarding-div-field-survey {
  @include display-align(column, space-evenly, center, null);
}

.pagination-container {
  display: flex;
  list-style-type: none;
  padding: 0;

  .pagination-item {
    text-align: center;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 0.75rem;
    border: 0.125rem solid #ced4f0;
    padding: 0.78125rem 1.09375rem;
    margin-right: 1rem;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #4d6cd9;
    background-color: #ffffff;

    &.dots:hover {
      background: none;
      cursor: default;
      box-shadow: none;
    }

    &.dots {
      border: none;
      cursor: default;
      background: none;
      padding: 0;
    }

    &:hover {
      background: #fbfbfb;
      cursor: pointer;
      box-shadow: 0px 4px 24px rgba(77, 108, 217, 0.16);
    }

    &.selected {
      border: 0.125rem solid #4d6cd9;
      background-color: #4d6cd9;
      color: #ffffff;
    }

    &.disabled {
      background: #fbfbfb;
      border: 0.125rem solid #a4a4a4;
      box-sizing: border-box;
      cursor: default;
      pointer-events: none;
    }

    .arrow {
      border: 0.125rem solid #4d6cd9;
      border-width: 0 0.125rem 0.125rem 0;
      display: inline-block;
      padding: 0.1875rem;
      cursor: pointer;
    }

    .arrow-right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    .arrow-left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }

    .arrow-disabled {
      border: 0.125rem solid #a4a4a4;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 0.1875rem;
      cursor: default;
      pointer-events: none;
    }
  }

  .pagination-item:last-child {
    margin-right: 0;
  }
}

@media (max-width: 50rem) {
  .pagination-item {
    padding: 0.5rem !important;
  }
}

@media (max-width: 30rem) {
  .pagination-item {
    padding: 0.35rem 0.3rem !important;
  }

  .pagination-item-left {
    display: none !important;
  }

  .pagination-item-right {
    display: none !important;
  }
}

@import "../../scssPartials/sharedStyles";

@mixin card-size($height, $width) {
  height: $height;
  width: $width;
}

.metric-card {
  @include display-align(column, space-evenly, center);
  width: 13.25rem;
  height: 13.625rem;
  border-radius: 1rem;
  // padding: 1rem;
  // margin: 1rem;
}

.metric-card-content {
  @include display-align(column, space-evenly, center);
}

.metric-card-icon-container {
  @include card-size(5rem, 5rem);
  @include display-align(column, center, center);
}

.metric-card-title {
  @include font(2.25rem, 700, #ffffff, 3.0625rem);
  @include card-size(3.0625rem, 8.4375rem);
  text-align: center;
}

.metric-card-desc {
  @include font(1.375rem, 700, #ffffff, 1.875rem);
  @include card-size(1.875rem, 14.3125rem);
  text-align: center;
}

@media only screen and (max-width: 720px) {
  .metric-card {
    width: 11rem !important;
    margin: auto;
    height: 15rem !important;
  }
  .metric-card-desc {
    @include font(1rem, 700, #ffffff, 1.875rem);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;
    top: -1rem;
  }
}

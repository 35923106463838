@import "../../scssPartials/sharedStyles";

.recruiter-profile-detail-card-wrapper {
  box-shadow: 0rem 0rem 5rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
}

.recruiter-profile-detail-card-container {
  position: relative;
  padding: 5.25rem 6.6875rem 1.5rem 6.6875rem;
  display: flex;
}

.recruiter-profile-detail-card-container-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 15rem;
  padding-right: 1rem;
  border-right: 0.0625rem solid #dfe5ff;
}

.recruiter-profile-detail-card-container-avatar {
  width: 6.25rem;
  height: 6.25rem;
}

.recruiter-profile-detail-card-profile-details-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1.25rem;
}

.recruiter-profile-detail-card-name {
  @include font(1.0625rem, bold, #30374c, 1.25rem);
}

.recruiter-profile-detail-card-location {
  @include font(1rem, normal, #626880, 1.375rem);
}

.recruiter-profile-detail-card-type {
  @include font(1rem, bold, #626880, 1.375rem);
  margin-top: 0.5rem;
}

.recruiter-profile-detail-card-profile-icons-container {
  display: flex;
  flex-direction: column;
  min-width: 26.125rem;
  padding-right: 1rem;
  border-right: 0.0625rem solid #dfe5ff;
}

.recruiter-profile-detail-card-profile-icon-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 1.125rem;
}

.recruiter-profile-detail-card-profile-icon-container:not(:first-child) {
  margin-top: 1.5rem;
}

.recruiter-profile-detail-card-profile-icon-image-container {
  display: flex;
}

.recruiter-profile-detail-card-profile-icons-container-text {
  @include font(0.875rem, bold, #626880, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-detail-card-profile-icons-container-label {
  @include font(0.875rem, normal, #626880, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-detail-card-stats-wrapper {
  margin-left: 1.125rem;
  background: #e9f0ff;
  border-radius: 1rem;
  padding: 1.125rem 1rem 1.125rem 1.125rem;
  min-width: 19.25rem;
  margin-top: 0.68625rem;
}

.recruiter-profile-detail-card-badges-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.125rem;
}

.recruiter-profile-detail-card-stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recruiter-profile-detail-card-stats-container:not(:first-child) {
  margin-top: 0.875rem;
}

.recruiter-profile-detail-card-stats-image-container {
  display: flex;
  align-items: center;
}

.recruiter-profile-detail-card-stat-text {
  margin-left: 0.75rem;
  @include font(0.875rem, normal, #626880, 1.125rem);
}

.recruiter-profile-detail-card-stats-container-text {
  @include font(1rem, bold, #626880, 1.375rem);
}

.recruiter-profile-detail-card-message-button {
  margin-top: 1.5rem;
  width: -webkit-fill-available;
}

.recruiter-profile-detail-card-stats-container-icon:not(:last-child) {
  margin-right: 0.75rem;
}

.recruiter-profile-detail-card-stats-container-tootltip {
  display: flex;
  align-items: center;
  padding: 0.625rem;
}

.recruiter-profile-detail-card-stats-container-tootltip-text {
  @include font(1rem, normal, #30374c, 1.375rem);
  margin-left: 0.625rem;
}

.recruiter-profile-detail-card-container-share {
  position: absolute;
  top: 1.5rem;
  right: 6.6875rem;
}

.recruiter-profile-detail-card-container-active-since {
  position: absolute;
  top: 1.5rem;
  left: 6.6875rem;
  @include font(0.875rem, bold, #aab0cb, 1.125rem);
}

@import "../../scssPartials/sharedStyles";

.header-wrapper {
  height: 4.5rem;
  width: 100%;
  background: #fefefe;
  box-shadow: 0px 4px 16px rgba(206, 212, 240, 0.8);
  opacity: 1;
  top: 0rem;
  max-width: inherit;
  z-index: 1;
}

.header {
  height: inherit;
  margin: auto 3.1875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo {
  margin-top: 0.5rem;
  height: 3.05rem;
}

.logo-wrapper {
  @extend %wrapper;
}

%wrapper {
  position: relative;
  display: inline-flex;
  height: inherit;
}

.header-buttons {
  display: flex;
  a {
    text-decoration: none;
  }
}

.header-signup-button,
.header-login-button {
  display: flex;
  justify-content: center;
}

.header-profile-icon {
  padding-left: 15.422875rem;
}

.header-login-button {
  margin-left: 1rem;
}

.header-menu {
  display: flex;
  align-items: center;
}

@media (max-width: 72.5rem) {
  .logo {
    width: 7.5rem;
  }
}

@media (max-width: 67rem) {
  .header {
    margin: auto 1.5rem;
  }
}

@media (max-width: 64rem) {
  .logo {
    width: 8.5rem;
  }

  .header-buttons {
    display: none;
  }
}

@import "../../scssPartials/sharedStyles";

@mixin footer-align($width, $left, $top) {
  display: inline-block;
  width: $width;
  margin-left: $left;
  padding-top: $top;
}

%ul-wrapper {
  ul {
    list-style-type: none;
    margin: 0 !important;
    padding-left: 0;
    li {
      padding-top: 1.25rem;
      @include nav-item-text(#8a90a9, #6e7690);
    }
  }
}

%div-wrapper {
  div {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: bold;
    color: #ced4f0;
  }
}

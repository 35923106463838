@import "../../scssPartials/sharedStyles";

.subscribe-container {
  max-width: 18.4375rem;
  text-align: center;
}

.subscribe-title {
  @include font(1.375rem, 700, #626880, 1.8125rem);
}

.subscribe-image {
  margin: 3rem 0 1.5rem 0;
}

.subscribe-button {
  width: -webkit-fill-available;
  width: -moz-available;
  margin-top: 1rem;
}

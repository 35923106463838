 .contests-contest-cards-container {
  margin: 1.5rem 1.5rem 0 1.5rem;
  padding-bottom: 7rem;
}

@media (min-device-width: 740px) {
  .contests-contest-cards-container {
    margin: 1.5rem 3.1875rem 0 3.1875rem;
  }
}

.contests-hero-image-container {
  margin: 0 3.1875rem;
  padding-top: 1.51125rem;

  @media (max-width: 739px) {
    display: none;
  }
}

.hero-image {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 15rem;
  height: 15rem;
}

.hero-image-content-container {
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 4rem 7.5rem;
  text-align: left;
}

.hero-image-title {
  font-weight: 700;
  font-size: 1.5rem;
  color: #ffffff;
  line-height: 2.0625rem;
}

.hero-image-content {
  margin-top: 0.846875rem;
  font-size: 1.0625rem;
  line-height: 1.37rem;
  color: #e8eafa;
  font-weight: 500;
}

.hero-image-button {
  margin: 1.5rem 0 1.6875rem 0;
}

.hero-image-image {
  align-self: flex-end;
}

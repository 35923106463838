.left-panel {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listElements {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.box {
  width: 20px;
  height: 20px;
  background-color: rgb(22, 246, 22);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blackBox {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: rgb(93, 90, 90);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tick {
  color: white;
  font-size: 20px;
}
.description {
  margin-top: 5px;
  font-size: small;
  margin-left: 1.9rem;
  color: gray;
}
.description.active {
  color: white;
}
.meter {
  width: 200px;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: rgb(11, 216, 141);
  border-radius: 10px;
  transition: width 0.5s;
}

.percentage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: black;
  font-weight: bold;
}

.right-panel {
  width: 70%;
  padding: 20px;
  margin-bottom: 75.6px;
}
.backBtn {
  position: absolute;
  bottom: 22px;
  left: 40%;
}

.nextBtn {
  position: absolute;
  display: flex;
  bottom: 20px;
  right: 20px;
}

.card {
  width: 80%;
  height: 90%;
  padding: 20px;
  background-color: rgb(43, 43, 197);
  color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 75.6px;
  margin-left: 20px;
  margin-bottom: 75.6px;
}
li {
  margin-bottom: 30px;
}
.HHlogo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.HHLogo {
  width: 90%;
  height: 80%;
}

.card button {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.right-panel {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 90%;
}

.right-panel label {
  margin-bottom: 5px;
}

.input-field {
  border-radius: 8px;
  border: 1.5px solid #ccc;
  padding: 10px;
  margin: 5px;
  width: 93%;
  border-color: rgb(65, 65, 252);
}
#input-field {
  border-radius: 8px;
  border: 1.5px solid #ccc;
  padding: 10px;
  margin: 5px;
  width: 96%;
  border-color: rgb(65, 65, 252);
}

.input-field.error {
  border-color: red;
}

.label {
  color: black;
}

.label.required::after {
  content: "*";
  color: red;
}
.error-message {
  color: red;
  font-size: 12px;
}

.skip {
  position: absolute;
  border-radius: 1rem;
  border-color: rgb(64, 220, 29);
  background-color: rgb(64, 220, 29);
  color: black;
  font-size: 1.2rem;
  cursor: pointer;
  height: 2rem !important;
  top: 0.5rem !important;
  right: 1.5rem !important;
}

.right-panel input {
  padding: 10px;
}

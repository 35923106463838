.contest-stats-container {
  height: 43.5rem;
  position: relative;
}

.group-my-stats {
  position: absolute;
  width: 22.90625rem;
  height: 27rem;
  left: 47.40625rem;
  top: 8.0625rem;
  border-radius: 0.5rem;
  text-align: right;
  font-weight: bold;
  font-size: 1.5rem;
}

.group-contest-stats {
  position: absolute;
  width: 22.90625rem;
  height: 27rem;
  left: 10rem;
  top: 8.0625rem;
  border-radius: 0.5rem;
  text-align: left;
  font-weight: bold;
  font-size: 1.5rem;
}

.contest-stats-heading-text {
  position: absolute;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.875rem;
  left: 10.375rem;
  top: 5.5rem;
}

.contest-stats-profiles-submitted-text-pos {
  position: absolute;
  width: 2.75rem;
  height: 1.8125rem;
  left: 3.75rem;
  top: 0.8125rem;
}

.my-stats-profiles-submitted-text-pos {
  position: absolute;
  height: 2.0625rem;
  right: 3.75rem;
  top: 0.8125rem;
}

.contest-stats-pro-shortlisted-text-pos {
  position: absolute;
  width: 2.75rem;
  height: 1.8125rem;
  left: 3.75rem;
  top: 4.6875rem;
}

.my-stats-pro-shortlisted-text-pos {
  position: absolute;
  height: 1.8125rem;
  right: 3.75rem;
  top: 4.6875rem;
}

.contest-stats-l1-done-text-pos {
  position: absolute;
  width: 2.75rem;
  height: 1.8125rem;
  left: 3.75rem;
  top: 8.75rem;
}

.my-stats-l1-done-text-pos {
  position: absolute;
  height: 1.8125rem;
  right: 3.75rem;
  top: 8.75rem;
}

.contest-stats-l2-done-text-pos {
  position: absolute;
  width: 1.8125rem;
  height: 1.8125rem;
  left: 3.75rem;
  top: 12.8125rem;
}

.my-stats-l2-done-text-pos {
  position: absolute;
  height: 1.8125rem;
  right: 3.75rem;
  top: 12.8125rem;
}

.contest-stats-hr-done-text-pos {
  position: absolute;
  width: 1.8125rem;
  height: 1.8125rem;
  left: 3.75rem;
  top: 16.875rem;
}

.my-stats-hr-done-text-pos {
  position: absolute;
  height: 1.8125rem;
  right: 3.75rem;
  top: 16.875rem;
}

.contest-stats-offer-out-text-pos {
  position: absolute;
  width: 1.8125rem;
  height: 1.8125rem;
  left: 3.75rem;
  top: 20.9375rem;
}

.my-stats-offer-out-text-pos {
  position: absolute;
  height: 1.8125rem;
  right: 3.75rem;
  top: 20.9375rem;
}

.contest-stats-joined-text-pos {
  position: absolute;
  width: 1.8125rem;
  height: 1.8125rem;
  left: 3.75rem;
  top: 25rem;
}

.my-stats-joined-text-pos {
  position: absolute;
  height: 1.8125rem;
  right: 3.75rem;
  top: 25rem;
}

.my-stats-heading-text {
  position: absolute;
  width: 6.625rem;
  height: 1.875rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #30374c;
  left: 63.75rem;
  top: 5.5rem;
  border-radius: nullpx;
}

.group-pyramid-boxes {
  position: absolute;
  width: 26.375rem;
  height: 31.9375rem;
  left: 27.3125rem;
  top: 8.0625rem;
  border-radius: 0rem;
}

@media only screen and (max-width: 780px) {
  .contest-stats-heading-text {
    position: relative;
    left: unset;
    top: unset;
    margin: 1rem;
  }
  .my-stats-heading-text {
    width: 100%;
    left: 0rem;
    top: 2rem;
    margin: 1rem;
  }
  .group-contest-stats {
    display: none;
  }
  .group-my-stats {
    display: none;
  }
  .group-pyramid-boxes {
    position: relative;
    left: 0px;
    width: 50%;
  }
  .contest-stats-container {
    height: auto;
  }
  .contest-stats-img {
    width: 20rem;
    height: 16rem;
    margin-left: -1.5rem;
  }
}

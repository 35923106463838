@import "../../scssPartials/sharedStyles";

.about-employer-wrapper {
  background: #ffffff;
  padding: 1.5rem 1.9365rem;
}

.about-employer-container {
  @include flex-row-container();
}

.about-employer-company-details {
  padding-right: 2rem;
  border-right: 1px solid #aab0cb;
  min-width: 28.0625rem;
  max-width: 28.0625rem;
}

.about-employer-company-logo {
  display: flex;
  width: 13.75rem;
  height: 6.25rem;
  object-fit: contain;
  background: #ffffff;
  @include border(0.125rem solid #e9f0ff, 1rem);
}

.about-employer-company-logo img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}

.about-employer-company-name-container {
  @include flex-row-container();
  justify-content: space-between;
  align-items: center;
}

.about-employer-company-name {
  @include font(1.375rem, bold, #30374c, 1.875rem);
  margin-top: 0.75rem;
}

.about-employer-company-rating {
  @include flex-row-container();
  @include font(1rem, normal, #626880, 1.375rem);
  align-items: center;
}

.about-employer-follow {
  height: 2.629375rem;
}

.about-employer-company-description {
  @include font(1rem, normal, #626880, 1.375rem);
  margin-top: 1.5rem;
}

.about-employer-company-contest-info {
  @include flex-row-container();
  margin-top: 3.3125rem;
}

.about-employer-company-contest-info-text-container {
  @include flex-row-container();
  align-items: center;
  margin-left: 0.5rem;
}

.about-employer-company-contest-info-text-container:not(:last-child) {
  margin-right: 0.8rem;
}

.about-employer-company-contest-info-text {
  @include font(1.375rem, bold, #30374c, 1.875rem);
}

.about-employer-company-contest-info-label {
  @include font(1rem, normal, #626880, 1.375rem);
}

.about-employer-company-contest-info-img {
  @include border(0.0625rem solid #dfe5ff, 1rem);
  padding: 0.890625rem 0.703125rem;
  width: 3.75rem;
  height: 3.75rem;
}

.about-employer-company-stats {
  padding: 0 3rem 1.5rem 2rem;
}

.about-employer-tags-container {
  @include flex-row-container();
  flex-wrap: wrap;
}

.about-employer-company-stats-sections {
  @include flex-row-container();
}

.about-employer-company-stats-container {
  @include flex-row-container();
  align-items: center;
  min-height: 2.75rem;
  margin-top: 0.5rem;
}

.about-employer-company-stats-text {
  @include font(1rem, normal, #626880, 1.375rem);
  margin-left: 0.9025rem;
}

.about-employer-company-stats-section1 {
  max-width: 17.35rem;
}

.about-employer-company-stats-section2 {
  margin-left: 6.094375rem;
  max-width: 18.8125rem;
}

.about-employer-separator {
  margin-top: 1.5rem;
  border-top: 0.0625rem solid #aab0cb;
}

.about-employer-benefits-offered {
  @include font(1.1875rem, bold, #30374c, 1.625rem);
  margin: 2.25rem 0;
}

.about-employer-benefits-container {
  @include flex-row-container();
  flex-wrap: wrap;
}

.about-employer-benefit-container {
  text-align: center;
}

.about-employer-benefit-container:not(:last-child) {
  margin-right: 7.125rem;
}

.about-employer-benefit-text {
  @include font(1.0625rem, normal, #30374c, 1.4375rem);
}

@media only screen and (max-width: 780px) {
  .about-employer-container {
    display: block;
  }
  .about-employer-company-name-container {
    justify-content: left;
    margin-top: 1rem;
  }
  .about-employer-follow {
    margin-left: 15px;
    height: 2rem;
  }
  .about-employer-company-contest-info {
    display: flex;
    // justify-content: space-between;
  }
  .about-employer-company-stats-sections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.75rem 1rem;
  }
  .about-employer-company-stats-section1 {
    max-width: 100%;
  }
  .about-employer-company-stats-section2 {
    max-width: 100%;
    margin-left: 0px;
  }
  .about-employer-company-stats {
    padding: 0px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .about-employer-company-stats-text {
    @include font(1rem, normal, #626880, 1.375rem);
    margin-left: 0.5rem;
  }
  .about-employer-company-name {
    @include font(1rem, bold, #30374c, 1.875rem);
    margin-top: unset;
  }
}

.button {
  padding: 0.455625rem 2.78125rem;
  border-radius: 0.75rem;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-primary {
  background: var(--primary-button-background);
  color: var(--primary-button-text-color);
  &:hover {
    background: var(--primary-button-hover-background);
    color: var(--primary-button-text-color);
  }
  &:active {
    background: var(--primary-button-active-background);
    color: var(--primary-button-text-color);
  }
  &:disabled {
    background: var(--primary-button-disabled-background);
    color: var(--primary-button-disabled-text-color);
    cursor: default;
  }
}

.button-secondary {
  background: var(--secondary-button-background);
  color: var(--secondary-button-text-color);
}

.button-outlined {
  border: 0.09375rem solid var(--outlined-button-border-color);
  background: var(--outlined-button-background);
  color: var(--outlined-button-text-color);
  &:hover {
    background: var(--outlined-button-active-background);
    color: var(--outlined-button-text-color);
  }
  &:focus {
    background: var(--outlined-button-active-background);
    color: var(--outlined-button-text-color);
  }
  &:disabled {
    background: var(--outlined-button-background);
    color: var(--primary-button-disabled-text-color);
  }
}

.button-textonly {
  color: var(--textonly-button-text-color);
  border: none;
  background-color: transparent;
  &:hover {
    color: var(--textonly-button-text-hover-color);
  }
  &:focus {
    color: var(--textonly-button-text-active-color);
    border: none;
  }
  &:disabled {
    color: var(--textonly-button-text-disabled-color);
  }
}

.button-option {
  border: 0.09375rem solid var(--option-button-unselected-border-color);
  background: var(--option-button-unselected-background);
  color: var(--option-button-unselected-text-color);
  &:focus {
    border: 0.09375rem solid var(--option-button-selected-border-color);
    background: var(--outlined-button-active-background);
    color: var(--option-button-selected-text-color);
  }
}
.button-option-selected {
  border: 0.09375rem solid var(--option-button-selected-border-color);
  background: var(--outlined-button-active-background);
  color: var(--option-button-selected-text-color);
}

@import "../../scssPartials/sharedStyles";

.partner-details-wrapper {
  background: #fefefe;
  box-shadow: 0px 0.25rem 1rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
  position: relative;
}

.partner-details-cover-image {
  margin: 0.5rem;
  min-height: 12.5rem;
  height: 12.5rem;
  border-radius: 1rem;
  width: -webkit-fill-available;
  width: -moz-available;
}

.partner-details-logo-container {
  position: absolute;
  top: 11.0625rem;
  left: 6.625rem;
  background: #ffffff;
  box-shadow: 0px 0px 1.25rem rgba(77, 108, 217, 0.16);
  border-radius: 1rem;
  height: 4.5rem;
  min-height: 4.5rem;
  width: 11.15375rem;
}

.partner-details-logo {
  object-fit: contain;
  width: -webkit-fill-available;
  width: -moz-available;
  border-radius: 1rem;
  padding: 0 1rem;
  min-height: 4.5rem;
}

.partner-details-container {
  margin: 3rem 6.625rem 0 6.625rem;
  padding-bottom: 1.5rem;
}

.partner-details-separator {
  border: 0.0625rem solid #dfe5ff;
  margin: 1rem 0 2rem 0;
}

.partner-details-name {
  @include font(1.375rem, 700, #475569, 1.875rem);
}

.partner-details-joining-date {
  @include font(0.8125rem, 700, #626880, 1.125rem);
}

.partner-details-label {
  @include font(1rem, 400, #30374c, 1.375rem);
}

.partner-details-text {
  @include font(1rem, 700, #30374c, 1.375rem);
  width: 15rem;
}

.partner-details-flex-container {
  display: flex;
}

.partner-details-max-width-label {
  width: 13.75rem;
  min-width: 13.75rem;
}

.partner-details-tags-container {
  @include flex-row-container();
  flex-wrap: wrap;
}

.partner-details-margin-top-container {
  margin-top: 1.5rem;
}

.partner-details-headquarter-title {
  @include font(1.375rem, 700, #30374c, 1.875rem);
  margin-bottom: 1.5rem;
}

.partner-details-website-text {
  cursor: pointer;
  color: #0000cc;
}

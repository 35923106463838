@import "../../scssPartials/sharedStyles";

.menu-items {
  display: flex;
  width: auto;
  height: inherit;
  justify-content: space-evenly;
}

.menu-icon {
  display: none;
}

.menu-item {
  display: inline-flex;
  line-height: inherit;
  height: inherit;
  align-items: center;
  a {
    @include nav-item-text(#30374c, #6e7690);
    border-radius: 0.75rem;
    padding: 0.625rem 1.5rem;
  }
}

.menu-item-active {
  a {
    @include nav-item-text(#4d6cd9, #9ba9e8);
    font-weight: bold;
    background: #dbe2f7;
    border-radius: 0.75rem;
    padding: 0.625rem 1.5rem;
  }
}

.mobile-menu-item {
  @include nav-item-text(#30374c, #6e7690);
  font-weight: bold;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #d6d6d6;
}

@media (max-width: 1220px) {
  .menu-item {
    a {
      padding: 1rem;
    }
  }
}

@media (max-width: 67.5rem) {
  .menu-item {
    a {
      padding: 0.9rem;
    }
  }
}

@media (max-width: 1024px) {
  .menu-items {
    display: none;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }
}

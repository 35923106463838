label {
  > input[type="radio"] {
    display: none;
    + {
      * {
        &::before {
          content: "";
          display: inline-block;
          vertical-align: bottom;
          width: 1rem;
          height: 1rem;
          margin-right: 0.3rem;
          border-radius: 50%;
          border-style: solid;
          border-width: 0.15rem;
          border-color: var(--primary-radio-button-color);
        }
        display: inline-block;
        padding: 0.5rem 1rem 0.5rem 0;
      }
    }
    &:checked {
      + {
        * {
          color: var(--secondary-radio-button-color);
          &::before {
            background: radial-gradient(
              var(--secondary-radio-button-color) 0%,
              var(--secondary-radio-button-color) 40%,
              transparent 50%,
              transparent
            );
            border-color: var(--secondary-radio-button-color);
          }
        }
      }
    }
  }
}
.radiogroup-verticle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

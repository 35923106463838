.error-wrapper {
  margin: 0 12.1875rem;
  padding: 6.53125rem 0;
}

.error-container {
  padding: 4.205625rem 5.05rem 4.58rem 7.90625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-title {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 3.0625rem;
}

.error-description {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #aab0cb;
  margin-top: 0.5rem;
}

.error-button {
  margin-top: 2rem;
}

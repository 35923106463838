.profile-card-wrapper {
  width: 21.375rem;
}

.profile-card-container {
  padding: 1rem;
}

.profile-card-image {
  width: 19.375rem;
  height: 15.875rem;
  object-fit: contain;
}

.profile-card-title {
  font-weight: bold;
  font-size: 1.1875rem;
  line-height: 1.625rem;
  margin: 0.25rem 0;
}

.profile-card-role {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0.25rem 0;
}

.profile-card-description {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 1.25rem 0 0 0;
  height: 11rem;
  justify-content: center;
}

.profile-card-separator {
  border: 0.0625rem solid #dfe5ff;
  margin: 1rem 0;
}

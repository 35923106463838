

.contest-width {
  width: 100%;
  margin: 1rem 0;
}

.contest-list-card-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contest-list-card-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contest-list-card-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 60%;
}

.contest-list-card-job-post-duration {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.8125rem;
  color: #aab0cb;
  margin-bottom: 1rem;
}

.contest-list-card-job-post-duration p {
  margin-left: 0.5rem;
}

.contest-list-card-top-icons-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
  
}

.contest-list-details-container {
  display: flex;
  flex-direction: column;
}

.contest-list-card-icons-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 5rem;
  margin-top: 3rem;
}

.contest-list-card-icon-stats-container {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.contest-list-card-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
}

.contest-list-card-icon-text-container {
  display: flex;
  align-items: center;
}

.contest-list-card-icon-text {
  font-weight: bold;
  font-size: 1.0625rem;
  color: #626880;
  margin-left: 0.375rem;
}

.contest-list-card-icons-container-text {
  font-size: 0.8125rem;
  color: #626880;
  margin-top: 0.25rem;
}

.contest-list-section-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
}

.contest-list-section {
  display: flex;
  flex-direction: column;
}

.contest-lists-detail-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.contest-lists-detail-text {
  font-size: 1rem;
  margin-left: 0.5rem;
}

.contest-list-card-join-hunt {
  align-self: flex-end;
  margin-top: auto;
}

.contest-list-card-join-hunt-button {
  width: 11.75rem;
}

@media (max-width: 768px) {
  .contest-list-card-container {
    flex-direction: column;
    align-items: center;
  }

  .contest-list-card-right {
    width: 100%;
  }

  .contest-list-card-icons-container {
    justify-content: center;
  }

  .contest-list-section-container {
    flex-direction: column;
    align-items: center;
  }

  .contest-list-card-join-hunt {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .contest-list-card-join-hunt-button {
    width: auto;
  }
}
.badgeDetails{
  display: flex;
}

.copyright-text {
  position: relative;
  bottom: 1.25rem;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #aab0cb;
}

.copyright-container {
  display: flex;
  justify-content: center;
}

.hotlist-list-card-container {
  display: flex;
  padding: 1.5rem;
  flex: 1;
}

.hotlist-list-card-container-section-1 {
  display: flex;
  width: 27rem;
  max-width: 27rem;
}

.hotlist-list-card-role {
  font-size: 1.125rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  height: 2.5rem;
}

.hotlist-list-card-id {
  color: var(--secondary-text-color);
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem 0 0.5rem 0;
}

.hotlist-list-card-details-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  width: -moz-available;
  margin-top: 1rem;
}

.hotlist-list-card-details {
  margin: 0.25rem 0;
}

.hotlist-list-card-container-section-1-image-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 20rem;
  align-items: center;
  margin-top: 2rem;
}

.hotlist-list-card-container-section-1-details {
  padding-right: 1rem;
  width: 15rem;
}

.hotlist-list-card-container-section-2 {
  position: relative;
  padding: 0 1rem;
  border-left: 1px solid #d6d6d6;
  flex-grow: 1;
  margin-top: 2rem;
}

.hotlist-list-card-container-section-2-text {
  margin-top: 1rem;
}

.hotlist-list-card-connect {
  margin-top: 1rem;
  align-self: flex-end;
}

.hotlist-card-add-list-card {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  background: #f3f3f3;
  border-radius: 8px;
  padding: 0px 1rem;
}

@media (max-width: 1200px) {
  .hotlist-list-card-container-section-1 {
    width: 25rem;
    max-width: 25rem;
  }
}

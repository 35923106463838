.js-connect-dialog-body {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  p {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.js-connect-dialog-email-input {
  width: 70%;
  margin: 1rem auto;
}

.js-connect-dialog-button {
  margin-top: 1rem;
  width: fit-content;
  align-self: center;
}

.invalid-feedback {
  margin: 0;
}

.MuiButtonBase-root {
  position: absolute;
}

.js-data-table {
  td
  {
    border-left:1px solid black;
    border-top:1px solid black;
  }
  table
  {
    border-right:1px solid black;
    border-bottom:1px solid black;
  }
}
.partners-wrapper {
  background: #ffffff;
  padding-bottom: 1rem;
}

.partners-title {
  font-weight: bold;
  font-size: 1.1875rem;
  line-height: 1.625rem;
  color: #30374c;
  padding-top: 2rem;
}

.partners-container {
  display: grid;
  grid-template-columns: repeat(5, minmax(10rem, 1fr));
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  margin-top: 2rem;
  padding-bottom: 2.1875rem;
}

.partners-separator {
  border-top: 0.06025rem solid #aab0cb;
  margin: 0;
}

.partners-no-partners {
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  color: #30374c;
  padding: 2rem 0;
}

@import "./footerPartials";
@import "../../scssPartials/sharedStyles";

.footer {
  padding: 10rem 3.1875rem 0 3.1875rem;
  width: 100%;
  background-image: url("../../assets/footer-background-new.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.footer-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-row-gap: 1.75rem;
  grid-column-gap: 2.5rem;
  padding-top: 2rem;
  width: 100%;
}

.logo {
  img {
    object-fit: contain;
    width: 9.375rem;
    height: 3.125rem;
    margin-bottom: 1.125rem;
  }

  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 1rem;
  line-height: 1.375rem;
}

.support {
  @extend %div-wrapper;
  @extend %ul-wrapper;
  color: #fff;
  max-width: 14.5625rem;
}

.support-title {
  color: #fff !important;
}

.support li {
  color: #fff !important;
}

.information {
  @extend %div-wrapper;
  @extend %ul-wrapper;
}

.recruiters {
  @extend %div-wrapper;
  @extend %ul-wrapper;
}

.separator {
  border: 0.0625rem solid #8a90a9;
  margin-top: 2rem;
  margin-bottom: 0;
}

.copyright-container {
  display: flex;
  justify-content: center;
}

.copyright {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #fff;
  text-align: center;
  margin: 1rem 0;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  width: 12rem;
  margin-top: 2rem;
  margin-bottom: 1.25rem;
}

.social-icon {
  max-width: 1.125rem;
}

.footer-item-disabled {
  opacity: 0.5 !important;
}

.footer-item-disabled:hover {
  color: #8a90a9 !important;
  font-weight: normal !important;
  cursor: default !important;
}

.footer-item-title,
.menu-item-container li {
  color: #fff !important;
}

.menu-item-container li {
  padding-top: 10px !important;
}

@media (max-width: 64rem) {
  .footer {
    background: #4d6cd9 0% 0% no-repeat padding-box;
    padding: 0 1.5rem;
  }
}





.edit-profile-banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 18.5rem;
}

.edit-profile-banner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0rem;
  top: 0rem;
  background: #4d6cd9;
}

.edit-profile-background {
  position: relative;  
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.edit-profile-heading-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  top: 9rem;
}

.edit-profile-heading {
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #ffffff;
}

.edit-profile-card {
  position: relative;
  width: 52.125rem;
  top: -5.6875rem;
}

@mixin timeline-item() {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 12.875rem;
  text-align: left;
  margin-left: 0.3125rem;
  top: -2.5rem;
  background: #ffffff;
  border: 0.09375rem solid #dfe5ff;
  border-radius: 0.75rem;
  padding: 0.5rem 0;
}

@mixin timeline-item-container() {
  list-style: none;
  margin: auto;
  border-left: 0.25rem solid #ced4f0;
  padding: 0 0 2.5rem 0.9375rem;
  position: relative;
}

@mixin timeline-item-container-before {
  position: absolute;
  left: -0.75rem;
  top: -1rem;
  content: " ";
  border: 0.25rem solid#4d6cd9;
  border-radius: 500%;
  background: #ced4f0;
  height: 0.75em;
  width: 0.75rem;
}

@mixin timeline-item-title {
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 0.5rem 0 0 1.53125rem;
}

@mixin timeline-item-description {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: bold;
  color: #30374c;
  padding: 0.375rem 0 0 1.53125rem;
}

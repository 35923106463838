.certificate-image-container {
  background: #ffffff;
  box-shadow: 0px 0.25rem 1rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
  padding: 1rem;
  max-width: fit-content;
}

.certificate-image {
  width: 30.0625rem;
  height: 23rem;
}

.certificate-buttons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
}

@import "../../scssPartials/sharedStyles";

.home-page-faq-container {
  background-color: #ffffff;
}

.home-page-faq-sections {
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
}

.home-page-faq-section {
  cursor: pointer;
}

.home-page-faq-section-title {
  @include font(1.0625rem, 600, #30374c, 1.4375rem);
  text-align: center;
}

.home-page-faqs {
  margin-top: 3rem;
}

@media only screen and (max-width: 720px) {
  .home-page-faq-sections {
    flex-direction: column;
    justify-self: center;
  }
  .home-page-faq-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
